import { Fragment } from "react";
import { Modal } from "react-bootstrap";
import { createPortal } from "react-dom";

const HooxModal = ({
  isShowing,
  hide,
  title,
  onlyTitle,
  children,
  type = HOOX_MODAL.INFO,
  style,
}) =>
  isShowing
    ? createPortal(
        <Fragment>
          <Modal
            show={isShowing}
            onHide={hide}
            dialogClassName={type === HOOX_MODAL.TEXT ? "modal-90w" : null}
            backdrop={type === HOOX_MODAL.ERROR ? "static" : true}
          >
            <Modal.Body
              style={
                type === HOOX_MODAL.TEXT
                  ? { textAlign: "left", ...style }
                  : { textAlign: "center", ...style }
              }
              className={`text-${type}`}
            >
              <div>
                {title && !onlyTitle && (
                  <div className="contentNav p-2">
                    <i
                      className="bi bi-exclamation-circle-fill"
                      style={{ fontSize: "35px" }}
                    ></i>
                  </div>
                )}
                {title && (
                  <Modal.Title className="p-2" style={{ fontWeight: "700" }}>
                    {title}
                  </Modal.Title>
                )}
                <span style={{ color: "white" }}>{children}</span>
              </div>
            </Modal.Body>
          </Modal>
        </Fragment>,
        document.body
      )
    : null;

export const HOOX_MODAL = {
  ERROR: "danger",
  INFO: "info",
  SUCCESS: "success",
  TEXT: "text",
};

export default HooxModal;
