/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { stringLimitCharacter } from "../util/stringLimitCharacter";
import { getRtukRatingSymbol } from "../util/rtukSymbols";

const ContentLink = styled(Link)`
  display: block;
  aspect-ratio: 16/9;
  width: 100%;
  height: auto;
  box-shadow: ${(props) => (props.shadow ? "-15px -2px 50px black" : "none")};
  position: relative;

  &:hover {
    & #content-detail {
      opacity: 1;
    }
  }
`;

const ContentImage = styled.div`
  width: 100%;
  height: 100%;
  background: url("${(props) => props.src}");
  background-position: center;
  background-size: cover;
  background-color: #000;
`;

const ContentDetail = styled.div`
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    color: white;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    & h1 {
      font-size: ${(props) => props.isMiddle ? "1.7rem" : "2.3rem"};
    }

    & p {
      display: inline-block;
      width: auto;
      padding-right: 3px;
      margin: 0;
      color: rgba(255, 255, 255, 0.8);
      line-height: 18px;
      font-size: ${(props) => props.isMiddle ? "16px" : "17px"};
    }

    & .content-border {
      margin: 10px 10px;
      border-left: 1px solid #bb00bb;
    }

    & .progress-bar {
      background-color: #bb00bb;
    }
  `;

function EventComponent(props) {
  const isMiddle = useMediaQuery({ query: "(max-width: 1500px)" });

  

  //console.log("event component", props)

  return (
    <ContentLink
      to={
        props.item.type === "MOVIE" || props.item.type === "SERIES"
          ? `/${props.item.urlName}/${props.item.id}/${4}`
          : `/${props.item.urlName}/${props.item.seriesId}/${4}`
      }
      $shadow={props.shadow}
    >
      <ContentImage src={props.item.posters[0].url || ""} />
      {props.listType === 2 ? (
        ""
      ) : (
        <ContentDetail id="content-detail" $isMiddle={isMiddle}>
          <h1>
            {props.item.titleBrief ? props.item.titleBrief : props.item.title}
          </h1>
          <Row style={{ width: "100%" }}>
            <Col className="col-7">
              <img
                src={
                  "/assets/img/rtuk/" +
                  getRtukRatingSymbol(props.item.rtukRating)
                }
                style={styles.symbol}
              />
            </Col>
            <Col className="col-5" style={{ textAlign: "right" }}>
              <p>{props.item.definitions}</p>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <p>{stringLimitCharacter(props.item.synopsisSh, 60)}</p>
            </Col>
          </Row>
          <Row>
            {props.item.leftDuration && (
              <ProgressBar now={60} style={{ height: "5px", width: "100%" }} />
            )}
          </Row>
        </ContentDetail>
      )}
    </ContentLink>
  );
}

export default EventComponent;

const styles = {
  symbol: {
    width: 26,
    marginBottom: 8,
  },
};
