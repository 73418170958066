import axios from "axios";

//export const apiUrl = "http://75.119.146.85:5555/"; //test
//export const apiUrl = "https://muudon.com/api/"; // staging
export const apiUrl = "https://hoox.tv/api/"; // prod
//85.111.52.240

export default axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("authToken"),
    device: "Web",
  },
});
