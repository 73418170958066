import React, { useMemo, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import "./App.css";
import Listing from "./pages/Listing";
import ContentDetail from "./pages/content/ContentDetail";
import LoginRegister from "./pages/Register";
import UserDetails from "./pages/UserDetails";
import Favorites from "./pages/Favorites";
import VideoPage2 from "./pages/VideoPage";
import Layout from "./components/Layout";
import { AuthContext } from "./components/AuthContext";
import Login from "./pages/Login";
import VideoPage from "./pages/VideoPage";
import Welcome from "./pages/Welcome";
import ProductSelection from "./pages/ProductSelection";
import PaymentDirect from "./pages/PaymentDirect";
import Payment3D from "./pages/Payment3D";
import PaymentFallback from "./pages/PaymentFallback";
import PaymentFail from "./pages/PaymentFail";

function App() {
  const [user, setUser] = useState({
    userName: localStorage.getItem("userName"),
    isLoggedIn: localStorage.getItem("isUser") === "true",
  });

  const userProviderValue = useMemo(
    () => ({
      user,
      setUser,
    }),
    [user]
  );

  return (
    <AuthContext.Provider value={userProviderValue}>
      <Router>
        <Routes>
          <Route
            path="/payment-fallback/:type"
            element={<PaymentFallback />}
          ></Route>
          <Route path="/payment/fail" element={<PaymentFail />}></Route>
          <Route exact path="/" element={<Layout />}>
            {user && user.isLoggedIn ? (
              <>
                <Route path="/" index element={<Welcome />} />
                <Route path="/home" element={<Home />}>
                  <Route path=":categoryName/:categoryId" element={<Home />} />
                </Route>
                <Route
                  path="/:contentUrl/:contentId/:categoryId"
                  element={<ContentDetail />}
                >
                  <Route path="play" element={<VideoPage />} />
                </Route>
                <Route path="/arama" element={<Listing />} />
                <Route path="/user/login" element={<Login />} />
                <Route path="/user/register" element={<LoginRegister />} />
                <Route path="/user-details" element={<UserDetails />} />
                <Route path="/favorites" element={<Favorites />} />
                <Route path="/videotest" element={<VideoPage2 />} />
                <Route path="/:labelUrl/:categoryId" element={<Listing />} />
                <Route
                  path="/user/product-selection/:guid"
                  element={<ProductSelection />}
                />
                <Route
                  path="/payment-direct/:guid/:packageId"
                  element={<PaymentDirect />}
                />
                <Route path="/payment-3d" element={<Payment3D />}></Route>
              </>
            ) : (
              <>
                <Route path="/" index element={<Welcome />} />
                <Route path="/user/login" element={<Login />} />
                <Route path="/user/register" element={<LoginRegister />} />
                <Route
                  path="/payment-direct/:guid/:packageId"
                  element={<PaymentDirect />}
                />
                <Route path="/payment-3d" element={<Payment3D />}></Route>
                <Route
                  path="/payment-fallback/:type"
                  element={<PaymentFallback />}
                ></Route>
                <Route
                  path="/user/product-selection/:guid"
                  element={<ProductSelection />}
                />
              </>
            )}
          </Route>
        </Routes>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
