import { React, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { fetchContent, releaseContent } from './contentSlice';

import { Outlet, useParams } from 'react-router-dom';
import ContentDetailBanner from '../../components/ContentDetailBanner';

import EpisodeViewer from '../../components/EpisodeViewer';
import { getRecommended, getRelatedContent } from '../../service/contentService';
import ContentList from '../../components/ContentList';
import { useMediaQuery } from 'react-responsive';

function ContentDetail() {

  const { contentId } = useParams();

  const dispatch = useDispatch();
  const content = useSelector((state) => state.content.detail);
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  const [relatedContents, setRelatedContents] = useState([]);

  useEffect(() => {
    dispatch(fetchContent(contentId));

    getRelatedContent(contentId).then((response) => { // getRecommended().then((response) => { 
      setRelatedContents(response.data);
    });

    return () => dispatch(releaseContent())
  }, [contentId]);

  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  return (
    <div style={{marginTop: isMobile ? '100px' : ''}}>

      <ContentDetailBanner content={content} />
      {content.type === 'SERIES' && <EpisodeViewer content={content} />}
      <ContentList contents={relatedContents} title="Önerilenler"/>
      <Outlet/>
    </div>
  );
}

export default ContentDetail;
