import React, { useEffect } from "react";
import { Placeholder } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { ContentWrapper } from "../components/commons/styled-bootstrap";
import MainCarousel from "../components/MainCarousel";
import MainTab from "../components/MainTab";
import { fetchCategories, selectCategory } from "../hooxSlice";

function Home() {
  const status = useSelector((state) => state.hoox.status);

  const dispatch = useDispatch();

  const { categoryId } = useParams();

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    const selectedCategory = categoryId ? parseInt(categoryId) : undefined;
    dispatch(selectCategory(selectedCategory));
  }, [dispatch, categoryId]);

  return (
    <>
      <ContentWrapper>
        {status === "loading" ? (
          <Placeholder animation="glow" >
            <Placeholder xs={12} style={{ height: "600px", background: 'rgba(255,255,255,.4)' }} />
          </Placeholder>
        ) : (
          <MainCarousel />
        )}
      </ContentWrapper>
      <MainTab />
    </>
  );
}

export default Home;
