import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import HooxModal, { HOOX_MODAL } from "../components/HooxModal";
import { Button, Container, Row } from "react-bootstrap";
import Header from "../components/Header";

const PaymantFallback = () => {
  const type = useParams().type;
  const navigate = useNavigate();
  const titleText = () => {
    if (type === "success") {
      return "Ödeme işleminiz başarıyla tamamlandı.";
    } else if (type === "fail") {
      return "Ödeme işleminiz başarısız oldu.";
    } else {
      return "Ödeme sırasında bir hata oluştu";
    }
  };

  useEffect(() => {
    setTimeout(() => {
      navigate("/user/login");
    }, 5000);
  }, [type]);

  return (
    <Container>
      <Header></Header>
      <HooxModal
        isShowing={true}
        title={titleText()}
        type={type === "success" ? HOOX_MODAL.SUCCESS : HOOX_MODAL.ERROR}
        onlyTitle={true}
      >
        <Row style={{ margin: "10px", textAlign: "center" }}>
          {type === "success"
            ? "Abonelik işleminiz tamamlanmıştır. Giriş sayfasına yönlendiriliyorsunuz."
            : ""}
        </Row>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button variant="secondary" onClick={() => navigate("/user/login")}>
            Kapat
          </Button>
        </div>
      </HooxModal>
    </Container>
  );
};

export default PaymantFallback;
