import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getBanners, getCategories } from "./service/contentService";

export const fetchCategories = createAsyncThunk(
  "categories/getCategories",
  async () => {
    const response = await getCategories();
    //console.log("categories", response);
    localStorage.setItem("categories", JSON.stringify(response.data));
    return response.data;
  }
);

export const fetchBanners = createAsyncThunk(
  "banners/getBanners",
  async (categoryId) => {
    const response = await getBanners(categoryId);
    return response.data;
  }
);

const c = JSON.parse(localStorage.getItem("categories"));

export const hooxSlice = createSlice({
  name: "hoox",
  initialState: {
    status: "idle",
    categories: c || [],
    selectedCategory: undefined,
    error: null,
    paymentHtml: null,
  },
  reducers: {
    selectCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setError(state, error) {
      state.error = error;
    },
    hideError(state) {
      state.error = null;
    },
    setPaymentHtml(state, html) {
      state.paymentHtml = html;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = "idle";
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        //console.log("fetchCategories", action);
        const { error } = action;
        state.status = "failed";
        state.error = error;
      })
      .addCase(fetchBanners.pending, (state) => {
        // state.status = 'loading';
      })
      .addCase(fetchBanners.fulfilled, (state, action) => {
        // state.status = 'idle';
        state.banners = action.payload;
      })
      .addCase(fetchBanners.rejected, (state, action) => {
        const { error } = action;
        // state.status = 'failed';
        state.error = error;
      });
  },
});

export const { selectCategory, setError, hideError, setPaymentHtml } =
  hooxSlice.actions;

export default hooxSlice.reducer;
