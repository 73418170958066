import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getContentsByCategory } from "../service/contentService";
import ContentList from "./ContentList";

function MainTab() {
  const selectedCategory = useSelector((state) => state.hoox.selectedCategory);
  const [contents, setContents] = useState([]);

  useEffect(() => {
    //console.log('MainTab useEffect');
    getContentsByCategory(selectedCategory)
      .then((responseSubs) => {
        setContents(responseSubs.data);
      })
      .catch((error) => console.log(error));
  }, [selectedCategory]);

  return (
    <>
      {contents &&
        contents?.map(
          ({ labelId, labelName, labelViewType, contentListResponses }) => {
            const spaceBetween =
              labelViewType === "NUMBER" || labelViewType === "NUMBERW"
                ? 130
                : 20;

            //console.log(labelViewType);

            return (
              <ContentList
                contents={contentListResponses}
                title={labelName}
                key={labelId}
                slidePerView={
                  labelViewType === "NUMBER" || labelViewType === "NUMBERW"
                    ? 2.8
                    : 3.8
                }
                spaceBetween={spaceBetween}
                showOrderNumber={
                  labelViewType === "NUMBER" || labelViewType === "NUMBERW"
                }
              />
            );
          }
        )}
    </>
  );
}

export default MainTab;
