import { createContext, useContext, useState } from "react";

export const AuthContext = createContext({
  user: {
    userName: null,
    isLoggedIn: false,
    //userId: null,
    guid: null,
  },
  setUser: () => {},
});

export function AuthProvider({ children }) {
  const [user, setUser] = useState({
    userName: localStorage.getItem("userName"),
    isLoggedIn: localStorage.getItem("isUser") === "true",
    //userId: localStorage.getItem("userId"),
    //customerId: localStorage.getItem("customerId"),
    guid: localStorage.getItem("guid"),
  });

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
