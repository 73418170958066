import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

export default function Payment3D() {
  const { paymentHtml } = useSelector((state) => state.hoox);
  const [content, setContent] = useState(paymentHtml);

  useEffect(() => {
    setContent(paymentHtml.payload);
  }, [paymentHtml]);
  return <div dangerouslySetInnerHTML={{ __html: content || "" }} />;
}
