import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import validator from "validator";
import {
  getPackages,
  customerCreate,
  getContracts,
} from "../service/contentService";

import HooxModal, { HOOX_MODAL } from "../components/HooxModal";
import useModal from "../components/hooks/useModal";
import ReactMarkdown from "react-markdown";

import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router";
import styled from "styled-components";

const FormControl = styled(Form.Control)`
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-radius: 7px !important;
  resize: none;
  height: 40px;
  font: normal normal normal 14px Montserrat;
  &:focus-visible {
    outline: rgba(0, 0, 0, 0.8) !important;
  }
  position: relative;
`;

/*
const FormControlMask = styled(ReactInputMask)`
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-radius: 7px !important;
  resize: none;
  height: 40px;
  color: #dfdee2;
  font: normal normal normal 14px Montserrat;
  &:focus-visible {
    outline: rgba(0, 0, 0, 0.8) !important;
  }
`;
*/

const ShowPasswordIcon = styled.i`
  position: absolute;
  top: 25%;
  right: 12px;
  cursor: pointer;
`;

function LoginRegister() {
  const changeAuthMode = () => {
    navigate("/user/login");
  };

  const { isShowing: isShowingSuccess, toggle: toggleSuccessModal } =
    useModal();
  const { isShowing: isShowingContract, toggle: toggleContractModal } =
    useModal();

  const [contracts, setContracts] = useState(undefined);

  const [packages, SetPackages] = useState(undefined);
  const [registerError, SetRegisterError] = useState(undefined);

  const navigate = useNavigate();

  const [registerPassword, setRegisterPassword] = useState("");
  const [registerMail, setRegisterMail] = useState("");
  const [fullName, setFullName] = useState("");
  const [gsmNo, setGsmNo] = useState("");
  const iytCheckRef = useRef(null);

  // Contract modal content
  const [contratContent, setContratContent] = useState("");

  useEffect(() => {
    if (packages === undefined) {
      getPackages()
        .then((response) => {
          SetPackages(response.data);
        })
        .catch((error) => console.log(error));
    }
  }, [packages]);

  useEffect(() => {
    if (contracts === undefined) {
      getContracts()
        .then((response) => {
          setContracts(response.data);
        })
        .catch((error) => console.log(error));
    }
  }, [contracts]);

  function ModelContent(data) {
    setContratContent(data);
    toggleContractModal();
  }

  const [emailError, setEmailError] = useState("");
  const [isMailValid, setValidMail] = useState(false);

  const [modalTitle, SetModalTitle] = useState(undefined);
  const { isShowing: isErrorModal, toggle: toggleErrorModal } = useModal();

  const [showPassword, setShowPassword] = useState(false);

  const validateEmail = (e) => {
    const email = e.target.value;
    setRegisterMail(e.target.value);
    if (validator.isEmail(email)) {
      setEmailError("");
      setValidMail(true);
    } else {
      setEmailError("*Lütfen Geçerli Bir Mail Adresi Giriniz");
      setValidMail(false);
    }
  };

  function registerSubmit(event) {
    event.preventDefault();
    if (
      registerPassword !== "" &&
      isMailValid &&
      fullName !== "" &&
      registerPassword.length >= 8
    ) {
      customerCreate(
        fullName,
        registerMail,
        registerPassword,
        gsmNo,
        iytCheckRef.current.checked ? 1 : 0
      ).then((response) => {
        if (response !== undefined && response.data.code === 200) {
          toggleSuccessModal();
          localStorage.setItem("customerId", response.data.data.id);
          navigate(`/user/product-selection/${response.data.data.guid}`);
        } else {
          SetModalTitle("Kayıtlı Kullanıcı Hatası");
          SetRegisterError(response.data.message);
          toggleErrorModal();
        }
      });
    } else {
      if (registerPassword === "" || registerMail === "" || fullName === "") {
        SetRegisterError("Lütfen tüm alanları doldurunuz.");
        toggleErrorModal();
      } else if (registerPassword.length < 8) {
        SetModalTitle("Şifre Hatası");
        SetRegisterError("Şifreniz en az 8 karakter olmalıdır.");
        toggleErrorModal();
      } else SetRegisterError("");
    }
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      style={{ backgroundSize: "cover" }}
      /* style={{ backgroundImage: `url(/assets/img/background.png)` }}  */
    >
      <div className="container" style={{ width: "75%" }}>
        <div
          className="row d-flex justify-content-center justify-content-md-start"
          style={{ paddingTop: "30px" }}
        >
          <div
            className="col-12"
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.25)",
              paddingBottom: "20px",
              borderRadius: "15px",
              minWidth: "300px",
              maxWidth: "450px",
              color: "#fff",
            }}
          >
            <form onSubmit={registerSubmit} className="mx-3">
              <div
                className="d-flex justify-content-center"
                style={{ margin: 20 }}
              >
                <h4>ÜYE OL</h4>
              </div>
              <hr
                style={{
                  color: "#12d6df",
                  opacity: "1",
                  margin: "15px 0 35px",
                }}
              ></hr>

              <Col sm="12">
                <div
                  style={{
                    marginBottom: "5px",
                    fontSize: "13px",
                  }}
                >
                  İSİM SOYİSİM
                </div>
                <FormControl
                  type="text"
                  id="search"
                  onChange={(e) => setFullName(e.target.value)}
                  required
                  placeholder="İsim Soyisim"
                />
              </Col>

              <Col sm="12" style={{ marginTop: "20px" }}>
                <div
                  style={{
                    marginBottom: "5px",
                    fontSize: "13px",
                  }}
                >
                  E-POSTA ADRESİ
                </div>
                <FormControl
                  type="email"
                  id="email"
                  onChange={(e) => validateEmail(e)}
                  placeholder="E-posta Adresin"
                />
                <p>{emailError}</p>
              </Col>

              {/* <Col sm="12" style={{ marginTop: "20px" }}>
                  <div
                    style={{
                      marginBottom: "5px",
                      fontSize: "13px",
                    }}
                  >
                    CEP TELEFONU
                  </div>
                  <FormControlMask
                        className="form-control"
                        type="text"
                        mask="(+\90) 999 999 99 99"
                        value={gsmNo}
                        onChange={(e) => setGsmNo(e.target.value)}
                        id="gsmno"
                        placeholder="Telefon Numaran"
                      />
                </Col> */}

              <Col sm="12">
                <div
                  style={{
                    marginBottom: "5px",
                    fontSize: "13px",
                  }}
                >
                  ŞİFRE
                </div>
                <div style={{ position: "relative" }}>
                  <FormControl
                    onChange={(e) => setRegisterPassword(e.target.value)}
                    id="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Şifre"
                  />
                  {showPassword ? (
                    <ShowPasswordIcon
                      className="bi bi-eye"
                      onClick={handleShowPassword}
                    ></ShowPasswordIcon>
                  ) : (
                    <ShowPasswordIcon
                      className="bi bi-eye-slash"
                      onClick={handleShowPassword}
                    ></ShowPasswordIcon>
                  )}
                </div>

                <span
                  style={{ fontSize: "12px", color: "rgb(204, 204, 204)" }}
                  className="d-flex justify-content-end justify-content-md-end"
                >
                  Şifreniz en az 8 karakter olmalıdır.
                </span>
              </Col>

              <Row style={{ marginTop: "30px" }}>
                <Col sm="1">
                  <input required type="checkbox" />
                </Col>
                <Col sm="11">
                  <label
                    onClick={() => ModelContent(contracts.kvkk)}
                    style={{
                      color: "#cccccc",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    Aydınlatma Metni
                  </label>{" "}
                  'ni okudum kabul ediyorum.
                  {/*}
                     ve{" "}
                    <label
                      onClick={() => ModelContent(contracts.userAgrrement)}
                      style={{
                        color: "#cccccc",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      Kullanıcı sözleşmesini{" "}
                    </label>{" "}
                    */}
                </Col>
              </Row>

              <Row style={{ marginTop: "10px" }}>
                <Col sm="1">
                  <input required type="checkbox" ref={iytCheckRef} />
                </Col>
                <Col sm="11">
                  Kişisel verilerime ilişkin{" "}
                  <label
                    onClick={() =>
                      ModelContent(
                        "MUUD MEDYA ile şahsen paylaşmış olduğum tüm kişisel verilerimin doğru ve bana ait olduğunu ve okuduğum AYDINLATMA METNİNDE yer alan açıklamalar kapsamı ile sınırlı olmak üzere işlenmesine, paylaşılmasına ve saklanmasına üye olarak açık rıza verdiğimi kabul ederim."
                      )
                    }
                    style={{
                      color: "#cccccc",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    Açık Rıza Metni
                  </label>{" "}
                  'ni onaylıyorum.
                </Col>
              </Row>

              <Row style={{ marginTop: "10px" }}>
                <Col sm="1">
                  <input type="checkbox" ref={iytCheckRef} />
                </Col>
                <Col sm="11">
                  Hoox dan haber almak istiyorum.
                  <label
                    onClick={() => ModelContent(contracts.iyt)}
                    style={{
                      color: "#cccccc",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    Ticari Elektronik İleti İzni
                  </label>{" "}
                  'ni okudum kabul ediyorum.
                </Col>
              </Row>

              {/*
                <Row style={{ marginTop: "10px" }}>
                  <Col sm="1">
                    <input required type="checkbox" />
                  </Col>
                  <Col sm="11">
                    <label
                      onClick={() => ModelContent(contracts.sellingContract)}
                      style={{
                        color: "#cccccc",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      Mesafeli Satış Sözleşmesini
                    </label>{" "}
                    ve{" "}
                    <label
                      onClick={() => ModelContent(contracts.preliminaryInfo)}
                      style={{
                        color: "#cccccc",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      Ön bilgilendirme formunu
                    </label>{" "}
                    okudum kabul ediyorum.
                  </Col>
                </Row>

 
                <Row style={{ marginTop: "10px" }}>
                  <Col sm="1">
                    <input type="checkbox" />
                  </Col>
                  <Col sm="11">
                    <label
                      onClick={() => ModelContent(contracts.cookieProtocol)}
                      style={{
                        color: "#cccccc",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      Çerez politikasını
                    </label>{" "}
                    okudum kabul ediyorum.
                  </Col>
                </Row>
*/}

              <Row
                className="justify-content-center"
                style={{ marginTop: "15px" }}
              >
                <Col sm="11" style={{ fontSize: "small" }}>
                  Hesap oluştur tıklayarak{" "}
                  <label
                    onClick={() => ModelContent(contracts.preliminaryInfo)}
                    style={{
                      color: "#cccccc",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    Üyelik Sözleşmesi
                  </label>{" "}
                  'ni kabul ediyorum.
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      style={{ marginTop: "20px", width: "50%" }}
                      className="btn btn-primary checkOutButton w-100"
                      type="submit"
                    >
                      HESAP OLUŞTUR
                    </Button>
                  </div>
                  <div
                    onClick={changeAuthMode}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "12px",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    GİRİŞ YAP
                  </div>
                </Col>
              </Row>
            </form>

            <div />
          </div>
          <HooxModal
            isShowing={isShowingSuccess}
            hide={toggleSuccessModal}
            title="BAŞARILI"
          >
            <p style={{ color: "#7b748c" }}>
              Kayıt Başarılı Bir Şekilde Oluşturuldu.
            </p>
          </HooxModal>
          <HooxModal
            isShowing={isShowingContract}
            hide={toggleContractModal}
            type={HOOX_MODAL.TEXT}
          >
            <ReactMarkdown>{contratContent}</ReactMarkdown>
            <Button variant="secondary" onClick={toggleContractModal}>
              Kapat
            </Button>
          </HooxModal>
          <HooxModal
            isShowing={isErrorModal}
            hide={toggleErrorModal}
            title={modalTitle}
            type={HOOX_MODAL.ERROR}
          >
            <Row style={{ margin: "10px", textAlign: "center" }}>
              <Col style={{ margin: "10px", textAlign: "center" }}>
                {registerError}
              </Col>
            </Row>
            <Row>
              <Col style={{ textAlign: "center" }}>
                <Button
                  variant="secondary"
                  onClick={toggleErrorModal}
                  style={{ width: "100px" }}
                >
                  Kapat
                </Button>
              </Col>
            </Row>
          </HooxModal>
        </div>
      </div>
    </div>
  );
}
export default LoginRegister;
