const DiscoverButton = ({ style, onButtonClick, isMobile }) => {
  return (
    <div
      className="discoverButton"
      style={isMobile ? { ...styles.container, ...style } : style}
    >
      <h2 style={isMobile ? styles.onlyResponsive : styles.only}>Sadece</h2>
      <div style={styles.hooxContainer}>
        <div style={isMobile ? styles.hooxResponsive : styles.hoox} />
        <h2 style={styles.only}>'ta</h2>
      </div>
      <button
        onClick={onButtonClick}
        className="btn btn-secondary outline-primary"
        style={styles.button}
      >
        KEŞFET
      </button>
    </div>
  );
};

export default DiscoverButton;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "32px",
  },
  only: {
    color: "white",
    fontSize: "44px",
    fontWeight: "bold",
  },
  hooxContainer: {
    display: "flex",
    alignItems: "baseline",
  },
  hoox: {
    backgroundImage: "url(/assets/img/hoox-logo-welcome.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left bottom",
    width: "180px",
    height: "70px",
  },
  button: {
    borderRadius: "6px",
    border: "1px solid #bc00bc",
    width: "160px",
    height: "36px",
    backgroundColor: "transparent",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    fontSize: "14px",
    fontWeight: 400,
  },

  onlyResponsive: {
    color: "white",
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: 0,
  },
  hooxResponsive: {
    backgroundImage: "url(/assets/img/hoox-logo-welcome.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left bottom",
    width: "126px",
    height: "49px",
    marginBottom: "32px",
  },
};
