import { Navbar, Nav, Button, Form, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

const GuestUserNav = ({ setExpanded, expanded }) => {
  return (
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="ms-auto mb-2 my-lg-0">
        <Link
          className="nav-link d-flex justify-content-end"
          to="/user/login"
          onClick={() => setExpanded(expanded ? false : expanded)}
        >
          <span className="login-button">GİRİŞ YAP</span>
        </Link>
        <Link
          className="nav-link d-flex justify-content-end"
          to="/user/register"
          onClick={() => setExpanded(expanded ? false : expanded)}
        >
          <span className="register-button">ÜYE OL</span>
        </Link>
      </Nav>
    </Navbar.Collapse>
  );
};

export default GuestUserNav;
