import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Button } from "react-bootstrap";

import {
  forceLogin,
  login,
  logout,
  resetPassword,
} from "../service/contentService";
import { useAuth } from "../components/AuthContext";
import HooxModal, { HOOX_MODAL } from "../components/HooxModal";
import useModal from "../components/hooks/useModal";
import styled from "styled-components";

const FormControl = styled(Form.Control)`
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-radius: 7px !important;
  resize: none;
  height: 40px;
  font: normal normal normal 14px Montserrat;
  &:focus-visible {
    outline: rgba(0, 0, 0, 0.8) !important;
  }
`;

const ShowPasswordIcon = styled.i`
  position: absolute;
  top: 25%;
  right: 12px;
  cursor: pointer;
`;

function Login() {
  const changeAuthMode = () => {
    navigate("/user/register");
  };
  const navigate = useNavigate();
  const { setUser } = useAuth();

  const [error, SetError] = useState(undefined);
  const [modalTitle, SetModalTitle] = useState(undefined);

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);

  const { isShowing: isShowingError, toggle: toggleErrorModal } = useModal();
  const { isShowing: isInvalidCredentials, toggle: toggleInvalidCredentials } =
    useModal();
  const { isShowing: resetPasswordVisible, toggle: toggleResetPassword } =
    useModal();
  const { isShowing: isSessionError, toggle: toggleSessionModal } = useModal();

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    logout().then((response) => {});
  }, []);

  function handleSubmit(event) {
    login(username, password)
      .then((response) => {
        if (
          response !== undefined &&
          response.data.code !== undefined &&
          response.data.code === 200
        ) {
          if (response.data.data.token !== undefined) {
            navigate("/home");
          }
          setUser({
            userName: response.data.data.username,
            isLoggedIn: true,
            //userId: response.data.data.id,
            //customerId: response.data.data.customerId
            guid: response.data.data.guid,
          });
        } else {
          if (response.data.code === 62) {
            SetError(response.data.message);
            SetModalTitle(`Aktif Oturum Limiti`);
            toggleSessionModal();
          } else if (response.data.code === 500) {
            SetError(response.data.message);
            SetModalTitle("Kullanıcı Bilgileri");
            toggleInvalidCredentials();
          } else {
            SetError(response.data.message);
            SetModalTitle("Bir Sorun Oluştu");
            toggleErrorModal();
          }
        }
      })
      .catch((err) => {
        SetError("Beklenmeyen bir hata oluştu.");
        toggleErrorModal();
      });
    event.preventDefault();
  }

  function handleForceLogin(event) {
    forceLogin(username, password)
      .then((response) => {
        if (
          response !== undefined &&
          response.data.code !== undefined &&
          response.data.code === 200
        ) {
          if (response.data.data.token !== undefined) {
            navigate("/home");
          }
          setUser({
            userName: response.data.data.username,
            isLoggedIn: true,
            //userId: response.data.data.id,
            //customerId: response.data.data.customerId
            guid: response.data.data.guid,
          });
        } else {
          SetError(response.data.message);
          toggleErrorModal();
        }
      })
      .catch((err) => {
        SetError("Beklenmeyen bir hata oluştu.");
        toggleErrorModal();
      });
    //event.preventDefault();
  }

  function handleResetPassword() {
    if (resetPasswordEmail === "") {
      // SetError("Lütfen e-posta adresinizi girin.");
      // toggleErrorModal();
      return;
    }
    resetPassword(resetPasswordEmail)
      .then((response) => {
        if (
          response !== undefined &&
          response.data.code !== undefined &&
          response.data.code === 200
        ) {
          setResetPasswordSuccess(true);
        } else {
          SetError(response.data.message);
          toggleErrorModal();
        }
      })
      .catch((err) => {
        SetError("Beklenmeyen bir hata oluştu.");
        toggleErrorModal();
      });
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      style={{ height: "80vh", backgroundSize: "cover" }}
      /* style={{ backgroundImage: `url(/assets/img/background.png)` }}  */
    >
      <div className="container" style={{ width: "75%" }}>
        <div
          className="row d-flex justify-content-center justify-content-md-start"
          style={{ paddingTop: "140px" }}
        >
          <div
            className="col-12"
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.25)",
              paddingBottom: "20px",
              borderRadius: "15px",
              minWidth: "300px",
              maxWidth: "450px",
              color: "#fff",
            }}
          >
            <Row style={{ margin: "10px", color: "#fff" }}>
              <form onSubmit={handleSubmit}>
                <div
                  className="d-flex justify-content-center"
                  style={{ margin: 10 }}
                >
                  <div
                    style={{
                      fontSize: "23px",
                      fontWeight: "500",
                    }}
                  >
                    GİRİŞ YAP
                  </div>
                </div>
                <hr
                  style={{
                    color: "#12d6df",
                    opacity: "1",
                    margin: "15px 0 35px",
                  }}
                />
                <Col sm="12">
                  <HooxModal
                    isShowing={isShowingError}
                    hide={toggleErrorModal}
                    title={modalTitle}
                    type={HOOX_MODAL.INFO}
                  >
                    <Row style={{ margin: "10px", textAlign: "center" }}>
                      <Col style={{ margin: "10px", textAlign: "center" }}>
                        {error}
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ textAlign: "center" }}>
                        <Button
                          variant="secondary"
                          onClick={toggleErrorModal}
                          style={{ width: "100px" }}
                        >
                          Kapat
                        </Button>
                      </Col>
                    </Row>
                  </HooxModal>

                  <HooxModal
                    isShowing={isSessionError}
                    hide={toggleSessionModal}
                    title={modalTitle}
                    type={HOOX_MODAL.INFO}
                  >
                    <Row style={{ margin: "10px", textAlign: "center" }}>
                      <Col style={{ margin: "10px", textAlign: "center" }}>
                        {error}
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ textAlign: "right" }}>
                        <Button
                          variant="secondary"
                          onClick={toggleSessionModal}
                          style={{ width: "100px" }}
                        >
                          Kapat
                        </Button>
                      </Col>
                      <Col style={{ textAlign: "left" }}>
                        <Button
                          variant="secondary"
                          onClick={() => handleForceLogin()}
                          style={{
                            marginRight: "10px",
                            backgroundColor: "rgb(187, 0, 187)",
                            width: "100px",
                          }}
                        >
                          Giriş Yap
                        </Button>
                      </Col>
                    </Row>
                  </HooxModal>

                  <HooxModal
                    isShowing={isInvalidCredentials}
                    hide={toggleInvalidCredentials}
                    title={modalTitle}
                    type={HOOX_MODAL.INFO}
                  >
                    <Row style={{ margin: "10px", textAlign: "center" }}>
                      <Col style={{ margin: "10px", textAlign: "center" }}>
                        {error}
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ textAlign: "center" }}>
                        <Button
                          variant="secondary"
                          onClick={toggleInvalidCredentials}
                          style={{ width: "100px" }}
                        >
                          Kapat
                        </Button>
                      </Col>
                    </Row>
                  </HooxModal>

                  <div
                    style={{
                      marginBottom: "5px",
                      fontSize: "13px",
                    }}
                  >
                    E-POSTA ADRESİN
                  </div>
                  <FormControl
                    value={username}
                    onChange={(e) => setUserName(e.target.value)}
                    type="email"
                    id="email"
                    placeholder="E-posta Adresin"
                  />
                </Col>
                <Col sm="12" style={{ marginTop: "20px" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        marginBottom: "5px",
                        fontSize: "13px",
                      }}
                    >
                      ŞİFREN
                    </div>
                    <div
                      onClick={toggleResetPassword}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "10px",
                        cursor: "pointer",
                      }}
                    >
                      ŞİFREMİ UNUTTUM
                    </div>
                  </div>
                  <HooxModal
                    isShowing={resetPasswordVisible}
                    hide={toggleResetPassword}
                    title={"Şifre Sıfırlama"}
                    type={
                      resetPasswordSuccess
                        ? HOOX_MODAL.SUCCESS
                        : HOOX_MODAL.INFO
                    }
                  >
                    <Row
                      className="d-flex justify-content-center"
                      style={{ margin: "10px" }}
                    >
                      <Col style={{ margin: "10px", textAlign: "center" }}>
                        {resetPasswordSuccess
                          ? "Şifreniz sıfırlanmıştır. Lütfen e-posta adresinizi kontrol edin."
                          : "Şifrenizi sıfırlamak için e-posta adresinizi girin."}
                      </Col>
                    </Row>

                    <FormControl
                      value={resetPasswordEmail}
                      onChange={(e) => setResetPasswordEmail(e.target.value)}
                      placeholder="E-posta adresin"
                      style={{ marginBottom: "20px" }}
                    />
                    <Row style={{ margin: "10px" }}>
                      <Col style={{ textAlign: "right" }}>
                        <Button
                          variant="secondary"
                          onClick={toggleResetPassword}
                          style={{ width: "100px" }}
                        >
                          Kapat
                        </Button>
                      </Col>
                      <Col style={{ textAlign: "left" }}>
                        <Button
                          hidden={resetPasswordSuccess}
                          style={{
                            marginRight: "10px",
                            backgroundColor: "rgb(187, 0, 187)",
                            width: "100px",
                          }}
                          variant="secondary"
                          onClick={handleResetPassword}
                        >
                          Gönder
                        </Button>
                      </Col>
                    </Row>
                  </HooxModal>

                  <div style={{ position: "relative" }}>
                    <FormControl
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type={showPassword ? "text" : "password"}
                      placeholder="Şifren"
                    />

                    {showPassword ? (
                      <ShowPasswordIcon
                        className="bi bi-eye"
                        onClick={handleShowPassword}
                      ></ShowPasswordIcon>
                    ) : (
                      <ShowPasswordIcon
                        className="bi bi-eye-slash"
                        onClick={handleShowPassword}
                      ></ShowPasswordIcon>
                    )}
                  </div>
                </Col>
                <Col sm="12">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      style={{
                        marginTop: "20px",
                        width: "50%",
                        fontSize: "12px",
                      }}
                      className="btn btn-primary checkOutButton w-100"
                      type="submit"
                    >
                      GİRİŞ YAP
                    </Button>
                  </div>
                  <div
                    onClick={changeAuthMode}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "12px",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    ÜYE OL
                  </div>
                </Col>
              </form>
            </Row>
            <div />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
