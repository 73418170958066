import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import  useVdocipher  from '../../hooks/use-vdocipher';
 import VideoLayer from '../layer/VideoLayer';

export default function VideoPlayer({setCloseButton}) {
  const {watching, detail} = useSelector((state) => state.content);
  const { loadVideo, isAPIReady } = useVdocipher();
  const container = useRef();
  const [videoRef , setVideoRef] = useState(null);
  const [player, setPlayer] = useState(null);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (!isAPIReady) return;
    if (!container.current) return;
    if (videoRef) return
    container.current.innerHTML = "";
    const player = loadVideo({
      otp: watching.otp,
      playbackInfo: watching.playbackInfo,
      configuration: {
        primaryColor: "#bc00bc",
        // controls: "off",
      },
      container: container.current,
    });
    setVideoRef(player);
  }, [isAPIReady, loadVideo, watching, videoRef]);
  
 
  const handleMouseEnter = () => {
    setHover(true);
    setCloseButton(true);
  }

  const handleMouseLeave = () => {
    setTimeout(() => {
      setHover(false);
      setCloseButton(false);
    }, 4100)
  }

  return (
    <>
      <div 
        className="vdo-container" 
        ref={container}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}    
      />
      <VideoLayer 
        videoRef={videoRef} 
        isAPIReady={isAPIReady} 
        isVisible={hover}
        setVisible={setHover} 
        style={styles.layer}
        content={detail}
        watchingContent={watching}
      />  
       
    </>
  );
}

const styles = {
  layer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "90vh",
    //backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1,
  }
}