import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getContentDetail, getEpisodes } from '../../service/contentService';

export const fetchContent = createAsyncThunk('content/getContent', async (contentId) => {
    const response = await getContentDetail(contentId);
    return response.data;
});

export const fetchEpisode = createAsyncThunk('content/getEpisode', async (episodeId) => {
    const response = await getContentDetail(episodeId);
    return response.data;
});

export const fetchEpisodes = createAsyncThunk('content/getEpisodes', async(seasonId) => {
    const response = await getEpisodes(seasonId);
    return response.data;
});

export const contentSlice = createSlice({
    name: 'content',
    initialState: {
        detail: {},
        episodes: [],
        watching: {}
    },
    reducers: {
        releaseContent: (state) => {
            state.detail = {};
            state.episodes = [];
        },
        selectWatchingContent: (state) => {
            state.watching = state.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchContent.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchContent.fulfilled, (state, action) => {
                state.status = 'idle';
                state.watching = action.payload;
                state.detail = action.payload;
            })
            .addCase(fetchContent.rejected, (state, action) => {
                const { error } = action;
                state.status = 'failed';
                state.error = error;
            })
            .addCase(fetchEpisode.fulfilled, (state, action) => {
                state.status = 'idle';
                state.watching = action.payload;
            })
            .addCase(fetchEpisodes.fulfilled, (state, action) => {
                state.episodes = action.payload;
            })
    },
});

export const { releaseContent } = contentSlice.actions;

export default contentSlice.reducer;