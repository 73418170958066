import { useEffect, useState } from 'react';

import { getFavorites } from '../service/contentService';

import { Container } from 'react-bootstrap';
import ContentList from '../components/ContentList';
import { ContentWrapper } from '../components/commons/styled-bootstrap';

function Favorites() {
  const [content, setContent] = useState({});

  useEffect(() => {
    getFavorites()
      .then((response) => {
        setContent(response.data);
      })
      .catch((error) => alert(error));
  }, []);

  return (
    <ContentWrapper>
    <Container fluid style={{ marginTop: '40px', marginBottom: `${30}vh` }}>
      {/* <ContentList contents={content.purchased} title="Satın Aldıkların" /> */}
      <ContentList contents={content.favorites} title="Favorilerim" />
      <ContentList contents={content.watched} title="İzlediklerim"/>
    </Container>
    </ContentWrapper>
  );
}
export default Favorites;
