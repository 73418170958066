const rtukSymbols = (parental, rating) => {
  const symbols = [];
  if (rating) symbols.push("TRAİ_" + rating + ".svg.png");
  if (parental)
    parental.forEach((e) => {
      symbols.push(getParentalSymbol(e));
    });
  return symbols;
};

// ["VH", "SC", "IB"];  //VH : siddet korku, SC : Sexuality, IB : olumsuz davranis
const getParentalSymbol = (e) => {
  switch (e) {
    case "VH":
      return "TRAİ_Şiddet.svg.png";
    case "SC":
      return "TRAİ_Cinsellik.svg.png";
    case "IB":
      return "TRAİ_Olumsuz_örnek.svg.png";
    default:
      break;
  }
};

export const getRtukRatingSymbol = (rating) => {
  return "TRAİ_" + rating + ".svg.png";
};

export default rtukSymbols;
