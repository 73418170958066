import { useEffect, useState } from "react";
import { Container, Navbar, Offcanvas } from "react-bootstrap";

import "./header.css";
import GuestUserNav from "./GuestUserNav";
import AuthUserNav from "./AuthUserNav";
import { useAuth } from "../AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

const HooxLogoImg = styled.img`
  height: 50px;
  margin-left: ${(props) => (props.isMobile ? "" : "90px")};
`;

function Header() {
  const { user } = useAuth();
  const { isLoggedIn } = user;
  const [expanded, setExpanded] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });

  return (
    <Navbar
      collapseOnSelect
      fixed="top"
      className="header"
      expand="md"
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)}
    >
      <Container fluid>
        <Navbar.Brand href={isLoggedIn ? "/home" : "/"} className="logo">
          <HooxLogoImg
            src="/assets/img/whitelogo.png"
            className="d-inline-block align-top"
            alt="Hoox Logo"
            isMobile={isMobile}
          />
        </Navbar.Brand>
        <Navbar.Toggle
          className="bg-white"
          aria-controls="responsive-navbar-nav"
        />
        <Navbar.Collapse id="responsive-navbar-nav">
            {isLoggedIn ? <AuthUserNav setExpanded={setExpanded} expanded={expanded}/> : <GuestUserNav setExpanded={setExpanded} expanded={expanded}/>}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
