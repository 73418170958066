import { Button, Col, Container, Row } from "react-bootstrap";
import Header from "../components/Header";
import HooxModal, { HOOX_MODAL } from "../components/HooxModal";
import { useNavigate } from "react-router";
import { useEffect } from "react";

function PaymentFail() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/user/login");
    }, 5000);
  }, []);

  return (
    <Container>
      <Header></Header>
      <HooxModal
        isShowing={true}
        title={"İşlem Başarısız"}
        type={HOOX_MODAL.ERROR}
        onlyTitle={true}
      >
        <Row style={{ margin: "10px", textAlign: "center" }}>
          <Col style={{ margin: "10px", textAlign: "center" }}>
            {"Ödeme işleminiz başarısız olmuştur."}
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button variant="secondary" onClick={() => navigate("/user/login")}>
            Kapat
          </Button>
        </div>
      </HooxModal>
    </Container>
  );
}
export default PaymentFail;
