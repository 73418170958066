import {
  Carousel,
  Col,
  Container,
  OverlayTrigger,
  Placeholder,
  Popover,
  Row,
  Stack,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import React, { useState, useEffect } from "react";
import { addFavorite, removeFavorite } from "../service/contentService";
import { ContentWrapper, HooxButton } from "./commons/styled-bootstrap";
import styled from "styled-components";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import {
  POSTER_WEB_DETAIL,
  USER_RIGHT_BUY_PACKAGE,
  USER_RIGHT_IN_PACKAGE,
} from "../util/constants";
import { checkEmptyObject } from "../util/checkEmptyObject";
import { useAuth } from "./AuthContext";
import useModal from "./hooks/useModal";
import HooxModal, { HOOX_MODAL } from "./HooxModal";
import { useMediaQuery } from "react-responsive";
import rtukSymbols from "../util/rtukSymbols";

const ContentButtonGroup = styled.div`
  & button:first-child {
    margin-left: 0;
  }
  & button {
    margin: 0 5px;
  }
`;

const ContentOverlay = styled.div`
  height: 100%;
  position: absolute;
  background: linear-gradient(0deg, rgba(0 0 0) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 1;
`;

const ContentMainTitle = styled.div`
  color: #ffffff;
  font-family: "GilroyMedium";
  font-size: ${(props) => (props.isMobile ? "25" : "84")}px;
  font-weight: bold;
  margin-bottom: ${(props) => (props.isMobile ? "0" : "20")}px;
  box-sizing: content-box;
  line-height: 110%;
`;

const ContentSubData = styled.div`
  display: inline !important;
  color: #ffffff;
  font-family: "GilroyLight";
  font-size: 25px;
  margin-bottom: 20px;
  box-sizing: content-box;
`;

const ContentSubDataDefinition = styled.div`
  display: inline !important;
  color: #ffffff;
  font-family: "GilroyLight";
  font-size: 20px;
  margin-bottom: 20px;
  box-sizing: content-box;
  border: 2px #bb00bb solid;
  border-radius: 20px;
  padding: 1px 5px 2px 5px;
  text-align: right !important;
`;

const ContentSynopsis = styled.div`
  margin-top: 7%;
  font-weight: 500;
  line-height: 120%;
  font-size: 20px;
  font-family: "GilroyLight";
  display: block;
`;

function ContentDetailBanner({ content }) {
  //console.log("content data", content)

  const { user } = useAuth();

  const { isLoggedIn, guid } = user;
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });

  const { usrRight, fav } = content;

  const [isFavorited, setIsFavorited] = useState(undefined);
  const [symbols, setSymbols] = useState([]);

  const navigation = useNavigate();

  const { isShowing: isShowingSuccess, toggle: toggleSuccessModal } =
    useModal();

  const { isShowing: isShowingLogin, toggle: toggleSuccessLogin } = useModal();

  useEffect(() => {
    setIsFavorited(fav);
    //console.log("rtuk", rtukSymbols(content.parental, content.rtukRating))
    setSymbols(rtukSymbols(content.parental, content.rtukRating));
  }, [fav]);

  const handleFavoriteClick = (e) => {
    // TODO store handle
    if (!content.fav) {
      addFavorite(content.id).then((response) => {
        setIsFavorited(!isFavorited);
      });
    }
    if (content.fav) {
      // TODO store handle
      removeFavorite(content.id).then((response) => {
        setIsFavorited(!isFavorited);
      });
    }
  };

  const getPoster = (posters = []) => {
    const poster = posters.find((poster) => poster.code === POSTER_WEB_DETAIL);
    return poster ? poster.url : "404.img";
  };

  const shareButtonSize = 48;
  const windowSize = {
    w: 750,
    h: 600,
  };

  const sharePopover = (
    <Popover id="share-popover">
      <Popover.Body>
        <FacebookShareButton url={String(window.location)}>
          <FacebookIcon size={shareButtonSize} />
        </FacebookShareButton>

        <TwitterShareButton url={String(window.location)}>
          <TwitterIcon size={shareButtonSize} />
        </TwitterShareButton>

        <WhatsappShareButton
          url={String(window.location)}
          separator=":: "
          windowWidth={windowSize.w}
          windowHeight={windowSize.h}
        >
          <WhatsappIcon size={shareButtonSize} />
        </WhatsappShareButton>

        <LinkedinShareButton
          url={String(window.location)}
          windowWidth={windowSize.w}
          windowHeight={windowSize.h}
        >
          <LinkedinIcon size={shareButtonSize} />
        </LinkedinShareButton>
      </Popover.Body>
    </Popover>
  );

  const handleWatch = (e) => {
    //console.log("usrRight:" + usrRight)
    if (isLoggedIn) {
      if (usrRight === USER_RIGHT_IN_PACKAGE) {
        navigation("play");
      } else {
        toggleSuccessModal();
        // modal a buton konulacak.
        // Modal açıp abonelik başlatmalısınız mesajı verilecek. OK derse alttkai paket sayfasına yönlendirilecek. Cancel derse sayfada kalacak.
        //navigation('/user/product-selection/' + guid)
      }
    } else {
      toggleSuccessLogin(); // modal a buton konulacak.
      //navigation('/user/login')
    }
  };

  return !checkEmptyObject(content) ? (
    <ContentWrapper>
      <Carousel controls={false} indicators={false}>
        <Carousel.Item key={content.id}>
          <ContentOverlay className="d-block w-100" />
          <img
            className="bd-placeholder-img"
            style={{ height: "auto", width: "100%" }}
            src={getPoster(content.posters)}
            alt=""
          />

          <HooxModal
            isShowing={isShowingSuccess}
            title="Abonelik İşlemi Gerekli"
            type={HOOX_MODAL.INFO}
          >
            <p style={{ color: "#ffffff" }}>
              İçeriği izlemek için abonelik başlatmalı veya paket
              yükseltmelisiniz.
            </p>
            <Button
              variant="secondary"
              onClick={() => navigation("/user/product-selection/" + guid)}
              style={{
                marginRight: "10px",
                backgroundColor: "rgb(187, 0, 187)",
              }}
            >
              Tamam
            </Button>
            <Button variant="secondary" onClick={toggleSuccessModal}>
              Kapat
            </Button>
          </HooxModal>
          <HooxModal
            isShowing={isShowingLogin}
            title="Giriş Yapmalısınız"
            type={HOOX_MODAL.INFO}
          >
            <p style={{ color: "#ffffff", marginBottom: "60px" }}>
              İçeriği izlemek için giriş yapmalısınız.
            </p>
            <Button
              variant="secondary"
              onClick={() => navigation("/user/login")}
              style={{
                marginRight: "10px",
                backgroundColor: "rgb(187, 0, 187)",
              }}
            >
              Tamam
            </Button>
            <Button variant="secondary" onClick={toggleSuccessLogin}>
              Kapat
            </Button>
          </HooxModal>

          <Carousel.Caption
            className="text-start"
            style={{ marginBottom: isMobile ? "" : "1%", left: "4%" }}
          >
            <Container>
              <Row className="align-items-center">
                <Col md={{ span: 6 }}>
                  <ContentMainTitle isMobile={isMobile}>
                    {content.title}
                  </ContentMainTitle>
                  {!isMobile ? (
                    <Row>
                      <Col className="col-6">
                        <ContentSubData>
                          {content.typeTitle
                            ? `${content.typeTitle} / `
                            : `${""}`}
                          {content.year}
                        </ContentSubData>
                        <Col>
                          {symbols &&
                            symbols.length !== 0 &&
                            symbols.map((item, index) => {
                              return (
                                <img
                                  src={"/assets/img/rtuk/" + item}
                                  key={index}
                                  style={styles.symbol}
                                />
                              );
                            })}
                        </Col>
                      </Col>
                      <Col className="col-6  d-flex justify-content-end align-items-center">
                        {content.definitions &&
                          content.definitions.length !== 0 && (
                            <ContentSubDataDefinition>
                              {" "}
                              {content.definitions}{" "}
                            </ContentSubDataDefinition>
                          )}
                      </Col>
                    </Row>
                  ) : null}

                  {!isMobile ? (
                    <div
                      style={{
                        fontFamily: "GilroyMedium",
                        fontSize: "25px",
                        fontWeight: "500",
                        marginTop: "25px",
                      }}
                    >
                      {content.genres
                        ? content.genres.map(function (item, index) {
                            return (
                              <span key={`demo_snap_${index}`}>
                                {(index ? ", " : "") + item}
                              </span>
                            );
                          })
                        : content.genres}
                    </div>
                  ) : null}

                  {!isMobile ? (
                    <ContentSynopsis>{content.synopsisLn}</ContentSynopsis>
                  ) : null}

                  <div className="content-detail-desc">
                    <p className="content-detail-actors">
                      {content.actors
                        ? content.actors.map(function (item, index) {
                            return (
                              <span key={`demo_snap_${index}`}>
                                {(index ? ", " : "") + item}
                              </span>
                            );
                          })
                        : ""}
                    </p>
                  </div>

                  <ContentButtonGroup style={{ marginTop: "1%" }}>
                    {content.type === "MOVIE" || content.type === "EPISODE" ? (
                      usrRight === USER_RIGHT_BUY_PACKAGE ? (
                        <HooxButton.Secondary
                          variant="primary"
                          className="text-start"
                          style={{
                            borderRadius: "15px",
                            backgroundColor: "white",
                            color: "black",
                            minWidth: "16 0px",
                          }}
                        >
                          <Stack direction="horizontal" gap={2}>
                            <i className="bi bi-play-fill" />
                            <div
                              className="me-auto text-center"
                              style={{ width: "100%" }}
                            >
                              KİRALA İZLE
                            </div>
                          </Stack>
                        </HooxButton.Secondary>
                      ) : (
                        <HooxButton.Secondary
                          variant="outline-secondary"
                          onClick={handleWatch}
                          style={{
                            borderRadius: "15px",
                            backgroundColor: "white",
                            color: "black",
                            width: "160px",
                          }}
                        >
                          <Stack direction="horizontal" gap={2}>
                            <i className="bi bi-play-fill" />
                            <div
                              className="me-auto text-center"
                              style={{ width: "100%" }}
                            >
                              İZLE
                            </div>
                          </Stack>
                        </HooxButton.Secondary>
                      )
                    ) : (
                      ""
                    )}

                    {isLoggedIn && (
                      <HooxButton.Secondary
                        variant="outline-secondary"
                        onClick={handleFavoriteClick}
                        style={{ borderColor: "#BB00BB", borderRadius: "15px" }}
                      >
                        {!isFavorited ? (
                          <i className="bi bi-heart"></i>
                        ) : (
                          <i
                            className="bi bi-heart-fill"
                            style={{ color: "#BB00BB" }}
                          ></i>
                        )}
                        {/* {!isFavorited ? `${'LİSTEME EKLE'}` : `${'LİSTEMDEN ÇIKAR'}` } */}
                      </HooxButton.Secondary>
                    )}

                    {isLoggedIn && (
                      <OverlayTrigger
                        trigger="click"
                        placement="top"
                        overlay={sharePopover}
                        rootClose={true}
                      >
                        <HooxButton.Secondary
                          variant="outline-secondary"
                          style={{
                            borderColor: "#BB00BB",
                            borderRadius: "15px",
                          }}
                        >
                          <i className="bi bi-reply-fill icon-flipped"></i>
                        </HooxButton.Secondary>
                      </OverlayTrigger>
                    )}
                  </ContentButtonGroup>
                </Col>
              </Row>
            </Container>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </ContentWrapper>
  ) : (
    <Placeholder animation="glow">
      <Placeholder
        xs={12}
        style={{ height: "600px", background: "rgba(255,255,255,.4)" }}
      />
    </Placeholder>
  );
}

const styles = {
  symbol: {
    width: 26,
    marginRight: 8,
  },
};

export default ContentDetailBanner;
