import Axios from "../util/apiConfig";
import { GetNewToken } from "./contentService";

Axios.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("authToken");
  config.headers.Authorization = token;
  return config;
});

export async function changeUser(name, surname, city, mail, phone) {
  return Axios.put("cus/customer/info", {
    customerInfo: {
      city,
      name,
      gender: "Male",
      gsmno: phone,
      surname,
      email: mail,
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

export async function finishSubscription() {
  return Axios.put("cus/subscriber/finish/subscription")
    .then((response) => response)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

export async function deleteAccount() {
  return Axios.delete("cus/account")
    .then((response) => response)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

export async function getSSS() {
  return Axios.get("cus/info/sss")
    .then((response) => response.data)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

export async function getAboutUs() {
  return Axios.get("cus/info/aboutus")
    .then((response) => response.data)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

// get profile images
export async function getProfileImages() {
  return Axios.get("cus/profiles/images")
    .then((response) => response.data)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

// add profile image
export async function addProfileImage(imageUrl) {
  return Axios.post("cus/profiles/images/add", imageUrl, {
    headers: { "Content-Type": "text/plain" },
  })
    .then((response) => response.data)
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
        GetNewToken();
      }
      return error;
    });
}

//getting data for directApi
export async function getTokenForDirectPayment(customerGuid, packageId) {
  return Axios.post(
    `/cus/subscriber/start/payment/dapi/${customerGuid}/${packageId}`
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
      }
      return error;
    });
}

export async function subscriberPayment(
  customerGuid,
  packageId,
  holderName,
  cardNumber,
  expiryMonth,
  expiryYear,
  cvv
) {
  return Axios.post(`/cus/subscriber/start/payment/dapi`, {
    customerGuid,
    packageId,
    holderName,
    cardNumber,
    expiryMonth,
    expiryYear,
    cvv,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && error.response.data.status === 440)
      ) {
      }
      return error;
    });
}
