import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Nav, Row, Col, Tab, Form, Button } from "react-bootstrap";
import validator from "validator";
import {
  getUserDetails,
  changePassword,
  changeUser,
  logout,
  contactUs,
} from "../service/contentService";
import {
  finishSubscription,
  deleteAccount,
  getSSS,
  getProfileImages,
  addProfileImage,
} from "../service/customerService";
import { useAuth } from "../components/AuthContext";
import useModal from "../components/hooks/useModal";
import HooxModal, { HOOX_MODAL } from "../components/HooxModal";
import styled from "styled-components";

import profileIcon from "../assets/img/profileIcon.svg";
import billingfoIcon from "../assets/img/billinginfo.svg";
import contactUsIcon from "../assets/img/contactus.svg";
import faqIcon from "../assets/img/faq.svg";
import logoutIcon from "../assets/img/logout.svg";
import ReactInputMask from "react-input-mask";

const NavMenuItemStyled = styled(Nav.Item)`
  & .nav-link {
    color: #fff;
    border-radius: 20px;
  }

  & .nav-link.active {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
  }

  &:hover {
    color: #fff;
  }
`;

const NavUserNameBoxStyled = styled("div")`
  color: #fff;
  border-bottom: 1px solid #12d6df;
  margin-bottom: 17px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TabTitle = styled("div")`
  font-size: 23px;
  font-weight: 500;
`;

const TabContent = styled(Tab.Content)`
  color: #fff;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  padding: 30px;
  font-size: 13px;
`;

const FormControl = styled(Form.Control)`
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-radius: 7px !important;
  resize: none;
  height: 40px;
  font: normal normal normal 14px Montserrat;
  &:focus-visible {
    outline: rgba(0, 0, 0, 0.8) !important;
  }
`;

const FormControlMask = styled(ReactInputMask)`
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-radius: 7px !important;
  resize: none;
  height: 40px;
  color: #dfdee2;
  font: normal normal normal 14px Montserrat;
  &:focus-visible {
    outline: rgba(0, 0, 0, 0.8) !important;
  }
`;

const FormSelect = styled(Form.Select)`
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-radius: 7px !important;
  border: 1px solid rgba(0, 0, 0, 0.7);
  height: 40px;
  color: #dfdee2;
  font: normal normal normal 14px Montserrat;

  &:focus {
    border-color: rgba(0, 0, 0, 0.8) !important;
  }
`;

const Line = styled("hr")`
  color: #12d6df;
  opacity: 1;
`;

/*
const FormCheckInput = styled("div")`
  & input {
    background-color: transparent;
    border: 2px solid #12d6df;
    width: 15px;
    height: 15px;

    &:focus {
      box-shadow: none;
    }

    &:checked[type="checkbox"] {
      background-color: #12d6df;
      border: 2px solid #12d6df;
    }
  }
`;
*/

export const InfoField = styled.p`
  display: block;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  border: 1px solid rgba(18 214 223 / 30%);
  width: 50%;
  padding: 10px;
`;

const Icon = styled("img")`
  margin-right: 16px;
`;

const City = function (id, name) {
  this.id = id;
  this.name = name;
};
const cities = [
  new City("34", "İstanbul"),
  new City("06", "Ankara"),
  new City("35", "İzmir"),
  new City("01", "Adana"),
  new City("02", "Adıyaman"),
  new City("03", "Afyonkarahisar"),
  new City("04", "Ağrı"),
  new City("05", "Amasya"),
  new City("07", "Antalya"),
  new City("08", "Artvin"),
  new City("09", "Aydın"),
  new City("10", "Balıkesir"),
  new City("11", "Bilecik"),
  new City("12", "Bingöl"),
  new City("13", "Bitlis"),
  new City("14", "Bolu"),
  new City("15", "Burdur"),
  new City("16", "Bursa"),
  new City("17", "Çanakkale"),
  new City("18", "Çankırı"),
  new City("19", "Çorum"),
  new City("20", "Denizli"),
  new City("21", "Diyarbakır"),
  new City("22", "Edirne"),
  new City("23", "Elazığ"),
  new City("24", "Erzincan"),
  new City("25", "Erzurum"),
  new City("26", "Eskişehir"),
  new City("27", "Gaziantep"),
  new City("28", "Giresun"),
  new City("29", "Gümüşhane"),
  new City("30", "Hakkari"),
  new City("31", "Hatay"),
  new City("32", "Isparta"),
  new City("33", "Mersin"),
  new City("36", "Kars"),
  new City("37", "Kastamonu"),
  new City("38", "Kayseri"),
  new City("39", "Kırklareli"),
  new City("40", "Kırşehir"),
  new City("41", "Kocaeli"),
  new City("42", "Konya"),
  new City("43", "Kütahya"),
  new City("44", "Malatya"),
  new City("45", "Manisa"),
  new City("46", "Kahramanmaraş"),
  new City("47", "Mardin"),
  new City("48", "Muğla"),
  new City("49", "Muş"),
  new City("50", "Nevşehir"),
  new City("51", "Niğde"),
  new City("52", "Ordu"),
  new City("53", "Rize"),
  new City("54", "Sakarya"),
  new City("55", "Samsun"),
  new City("56", "Siirt"),
  new City("57", "Sinop"),
  new City("58", "Sivas"),
  new City("59", "Tekirdağ"),
  new City("60", "Tokat"),
  new City("61", "Trabzon"),
  new City("62", "Tunceli"),
  new City("63", "Şanlıurfa"),
  new City("64", "Uşak"),
  new City("65", "Van"),
  new City("66", "Yozgat"),
  new City("67", "Zonguldak"),
  new City("68", "Aksaray"),
  new City("69", "Bayburt"),
  new City("70", "Karaman"),
  new City("71", "Kırıkkale"),
  new City("72", "Batman"),
  new City("73", "Şırnak"),
  new City("74", "Bartın"),
  new City("75", "Ardahan"),
  new City("76", "Iğdır"),
  new City("77", "Yalova"),
  new City("78", "Karabük"),
  new City("79", "Kilis"),
  new City("80", "Osmaniye"),
  new City("81", "Düzce"),
];

const subjects = ["Öneri", "Abonelik", "Ödeme", "İçerik", "Şikayet"];

function UserDetails() {
  const { setUser } = useAuth();
  const { isShowing: isShowingSuccess, toggle: toggleSuccessModal } =
    useModal();
  const { isShowing: contactModalVisible, toggle: toggleContactModal } =
    useModal();
  const { isShowing: isShowingDelete, toggle: toggleDeleteModal } = useModal();
  const { isShowing: isShowingFinish, toggle: toggleFinishModal } = useModal();
  const { isShowing: isShowingAvatar, toggle: toggleAvatarModal } = useModal();
  const { user } = useAuth();

  const { guid } = user;

  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = React.useState("");

  const [userCredentials, setUserCredentials] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [contactMessage, setContactMessage] = useState("");
  const [contactSubject, setContactSubject] = useState(subjects[0]);
  const [contactModalType, setContactModalType] = useState(true);
  const [subscriptionModalType, setSubscriptionModalType] = useState(false);
  const [subscriptionModalText, setSubscriptionModalText] = useState(
    "Aboneliğinizi iptal etmek istediğinize emin misiniz?"
  );
  const [sss, setSss] = useState([]);
  const [edit, setEdit] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [avatarHover, setAvatarHover] = useState(false);

  useEffect(() => {
    getUserDetails()
      .then((response) => {
        setUserDetails(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    getSSS().then((response) => {
      setSss(JSON.parse(response.data));
    });
  }, [userDetails]);

  const handleChange = (e) => {
    const { value, id } = e.target;
    userDetails[id] = value;
    setUserDetails({ ...userDetails });
  };

  const handleCredentialChange = (e) => {
    const { value, id } = e.target;
    userCredentials[id] = value;
    setUserCredentials({ ...userCredentials });
  };

  function logoutSubmit() {
    logout().then(() => {
      setUser({ userName: null, isLoggedIn: false });
      localStorage.clear();
      navigate("/");
    });
  }

  function handleDeleteAccount() {
    deleteAccount().then(() => {
      setUser({ userName: null, isLoggedIn: false });
      localStorage.clear();
      navigate("/");
    });
  }

  function handleFinishSubscription() {
    finishSubscription().then((res) => {
      setSubscriptionModalType(true);
      setSubscriptionModalText(res.data.message);
    });
  }

  function passwordChangeSubmit(event) {
    const { oldPassword, newPassword, newPasswordRepeat } = userCredentials;
    if (oldPassword !== "" && newPassword !== "" && newPasswordRepeat !== "") {
      changePassword(oldPassword, newPassword, newPasswordRepeat).then(
        (response) => {
          if (response !== undefined && response.data.code === 200) {
            toggleSuccessModal();
          } else {
            setErrorMessage(response.data.message);
          }
        }
      );
      event.preventDefault();
    }
  }

  function changeUserDetails(event) {
    const { name, surname, city, email, gsmno, address, indetityNo } =
      userDetails;
    if (validator.isEmail(email)) {
      //var clearGsm = gsmno.split(" ", 1);
      changeUser(name, surname, city, email, gsmno, address, indetityNo).then(
        (response) => {
          if (response !== undefined && response.data.code === 200) {
            toggleSuccessModal();
          } else {
            setErrorMessage(response.data.message);
          }
        }
      );
      event.preventDefault();
    }
  }

  function handleContactForm(event) {
    if (contactMessage === "") {
      return;
    }
    contactUs(contactMessage, contactSubject)
      .then((response) => {
        if (response !== undefined && response.status === 200) {
          setContactModalType(true);
          toggleContactModal();
          setContactMessage("");
        } else {
          setContactModalType(false);
          toggleContactModal();
          setContactMessage("");
        }
      })
      .catch((err) => {
        setContactModalType(false);
        toggleContactModal();
      });

    event.preventDefault();
  }

  function toggleEdit() {
    setEdit(!edit);
  }

  useEffect(() => {
    getProfileImages().then((response) => {
      setProfiles(response.data);
    });
  }, []);

  function onAvatarClick() {
    toggleAvatarModal();
  }

  async function onAvatarSelect(imageUrl) {
    await addProfileImage(imageUrl);
    // refresh page
    window.location.reload();
  }

  return (
    <div className="user-details">
      <div
        className="container"
        style={{ marginTop: "15vh", marginBottom: "20vh" }}
      >
        <Tab.Container defaultActiveKey="hesabim">
          <Row>
            <Col md={3}>
              <Nav variant="pills" className="flex-column">
                <NavUserNameBoxStyled>
                  <div>
                    <div>Merhaba,</div>
                    {userDetails && (
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "500",
                          marginBottom: "10px",
                        }}
                      >
                        {userDetails.name}
                      </div>
                    )}
                  </div>
                  {userDetails ? (
                    <img
                      onClick={onAvatarClick}
                      style={
                        avatarHover
                          ? {
                              marginBottom: 8,
                              width: 48,
                              height: 48,
                              opacity: 0.6,
                            }
                          : {
                              marginBottom: 8,
                              width: 48,
                              height: 48,
                            }
                      }
                      src={userDetails.imageUrl || "/assets/img/man.png"}
                      alt="profile"
                      onMouseEnter={() => setAvatarHover(true)}
                      onMouseLeave={() => setAvatarHover(false)}
                    />
                  ) : null}
                </NavUserNameBoxStyled>
                <NavMenuItemStyled>
                  <Nav.Link
                    eventKey="hesabim"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Icon src={profileIcon} alt="Profile Icon" width="16" />{" "}
                    <span>Hesap Ayarlarım</span>
                  </Nav.Link>
                </NavMenuItemStyled>
                <NavMenuItemStyled>
                  <Nav.Link
                    eventKey="fatura-bilgilerim"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Icon src={billingfoIcon} alt="Profile Icon" width="16" />{" "}
                    <span>Fatura Bilgilerim</span>
                  </Nav.Link>
                </NavMenuItemStyled>
                <NavMenuItemStyled>
                  <Nav.Link
                    eventKey="iletisime-gec"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Icon src={contactUsIcon} alt="Profile Icon" width="16" />{" "}
                    <span>İletişime Geç</span>
                  </Nav.Link>
                </NavMenuItemStyled>
                <NavMenuItemStyled>
                  <Nav.Link
                    eventKey="sik-sorulan-sorular"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Icon src={faqIcon} alt="Profile Icon" width="16" />{" "}
                    <span>Sık Sorulan Sorular</span>
                  </Nav.Link>
                </NavMenuItemStyled>
                <NavMenuItemStyled>
                  <Nav.Link
                    onClick={logoutSubmit}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Icon src={logoutIcon} alt="Profile Icon" width="16" />{" "}
                    <span>Çıkış Yap</span>
                  </Nav.Link>
                </NavMenuItemStyled>
              </Nav>
            </Col>
            <Col md={6}>
              <TabContent>
                <Tab.Pane eventKey="hesabim">
                  <TabTitle style={styles.editTitle}>
                    <div>HESAP AYARLARIM</div>
                    <i
                      onClick={toggleEdit}
                      style={{
                        fontSize: 18,
                        paddingRight: 22,
                        color: edit ? "#12d6df" : "white",
                      }}
                      className={edit ? "bi bi-pencil-fill" : "bi bi-pencil"}
                    ></i>
                  </TabTitle>
                  <Line></Line>
                  <Form
                    onSubmit={changeUserDetails}
                    style={{ marginTop: "30px" }}
                  >
                    <Form.Group className="mb-3">
                      <Form.Label>İSİM SOYİSİM</Form.Label>
                      <FormControl
                        id="name"
                        value={userDetails.name || ""}
                        placeholder="İsim Soyisim"
                        onChange={handleChange}
                        disabled={!edit}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>ŞEHİR</Form.Label>
                      <FormSelect
                        id="city"
                        value={userDetails.city || "İstanbul"}
                        onChange={handleChange}
                        disabled={!edit}
                      >
                        {cities.map((city) => (
                          <option value={city.name} key={city.name}>
                            {city.name}
                          </option>
                        ))}
                      </FormSelect>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>E-POSTA ADRESİ</Form.Label>
                      <FormControl
                        id="email"
                        value={userDetails.email || ""}
                        placeholder="Eposta Adresin"
                        onChange={handleChange}
                        disabled
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>TELEFON NUMARASI</Form.Label>
                      <FormControlMask
                        className="form-control"
                        type="text"
                        mask="(+\90) 999 999 99 99"
                        value={userDetails.gsmno || ""}
                        onChange={handleChange}
                        id="gsmno"
                        placeholder="Telefon Numaran"
                        minLength={10}
                        disabled={!edit}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>ABONELİK PAKETİ</Form.Label>
                      <InfoField>{userDetails.product?.name || "-"}</InfoField>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>ABONELİK DURUMU</Form.Label>
                      <InfoField>{userDetails.status || "-"}</InfoField>

                      {userDetails.status === "ACTIVE" ? (
                        ""
                      ) : (
                        <Link
                          to={
                            "/user/product-selection/" +
                            localStorage.getItem("guid")
                          }
                          style={{ color: "white", fontSize: "15px" }}
                        >
                          Abonelik Paketleri
                        </Link>
                      )}
                    </Form.Group>

                    {/*   <Form.Group className="mb-3">
                      <Form.Label>İZİNLERİM</Form.Label>
                      <FormCheckInput className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="check1"
                          name="option1"
                          value=""
                        />
                        <Form.Label style={{ marginBottom: "20px" }}>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod
                        </Form.Label>
                      </FormCheckInput>
                      <FormCheckInput className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="check1"
                          name="option1"
                          value="something"
                        />
                        <Form.Label>
                          Lorem ipsum dolor sit amet, consectetur
                        </Form.Label>
                      </FormCheckInput>
                    </Form.Group> */}

                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        style={{ marginBottom: "15px", width: "50%" }}
                        className="btn btn-primary checkOutButton w-100"
                        type="submit"
                      >
                        KAYDET
                      </Button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        style={{ marginBottom: "15px", width: "49%" }}
                        className="btn btn-primary cancelButton "
                        type="button"
                        onClick={toggleFinishModal}
                      >
                        Abonelik Sonlandır
                      </Button>
                      <Button
                        style={{ marginBottom: "15px", width: "49%" }}
                        className="btn btn-primary cancelButton "
                        type="button"
                        onClick={toggleDeleteModal}
                      >
                        Hesabımı Sil
                      </Button>
                    </div>
                  </Form>
                  <Line></Line>
                  <TabTitle style={{ marginBottom: "15px" }}>
                    ŞİFRE DEĞİŞTİR
                  </TabTitle>
                  <Form onSubmit={passwordChangeSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Label>ESKİ ŞİFRE</Form.Label>
                      <FormControl
                        id="oldPassword"
                        value={userCredentials.oldPassword}
                        placeholder="Eski Şifre"
                        onChange={handleCredentialChange}
                        type="password"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>YENİ ŞİFRE</Form.Label>
                      <FormControl
                        id="newPassword"
                        value={userCredentials.newPassword}
                        placeholder="Yeni Şifre"
                        onChange={handleCredentialChange}
                        type="password"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>YENİ ŞİFRE TEKRAR</Form.Label>
                      <FormControl
                        id="newPasswordRepeat"
                        value={userCredentials.newPasswordRepeat}
                        placeholder="Yeni Şifre Tekrar"
                        onChange={handleCredentialChange}
                        type="password"
                      />
                    </Form.Group>

                    <p>{errorMessage}</p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "30px",
                      }}
                    >
                      <Button
                        style={{ width: "50%" }}
                        className="btn btn-primary checkOutButton w-100"
                        type="submit"
                      >
                        KAYDET
                      </Button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                        fontSize: "11px",
                      }}
                    >
                      İPTAL ET
                    </div>
                  </Form>
                </Tab.Pane>
                <Tab.Pane eventKey="fatura-bilgilerim">
                  <TabTitle>
                    <div>FATURA BİLGİLERİM</div>
                  </TabTitle>
                  <Line></Line>
                  <Form
                    onSubmit={changeUserDetails}
                    style={{ marginTop: "30px" }}
                  >
                    <Form.Group className="mb-3">
                      <Form.Label>TCK NO</Form.Label>
                      <FormControl
                        id="identityNo"
                        placeholder="TCKNO"
                        onChange={handleChange}
                        maxLength="11"
                        pattern="[0-9]{11}"
                        title="11 Haneli TCK NO girmelisiniz."
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>ADRES</Form.Label>
                      <FormControl
                        id="address"
                        placeholder="ADRES"
                        onChange={handleChange}
                        as="textarea"
                        style={{
                          color: "#dfdee2",
                          padding: "10px",
                          boxSizing: "border-box",
                          width: "100%",
                          height: "100px",
                          borderColor: "rgba(0,0,0,0.8)",
                        }}
                      />
                    </Form.Group>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        style={{ marginBottom: "15px", width: "50%" }}
                        className="btn btn-primary checkOutButton w-100"
                        type="submit"
                      >
                        KAYDET
                      </Button>
                    </div>
                  </Form>
                </Tab.Pane>
                <Tab.Pane eventKey="iletisime-gec">
                  <TabTitle>
                    <div>İLETİŞİM FORMU</div>
                  </TabTitle>
                  <Line />
                  <Form onSubmit={handleContactForm}>
                    <Form.Group className="mb-3">
                      <Form.Label>KONU</Form.Label>
                      <FormSelect
                        id="subject"
                        defaultValue={subjects[0]}
                        onChange={(e) =>
                          setContactSubject(e.currentTarget.value)
                        }
                      >
                        {subjects.map((subject) => (
                          <option value={subject} key={subject}>
                            {subject}
                          </option>
                        ))}
                      </FormSelect>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label style={{ marginTop: "5px" }}>
                        MESAJINIZ
                      </Form.Label>
                      <FormControl
                        id="message"
                        value={contactMessage}
                        onChange={(e) => setContactMessage(e.target.value)}
                        placeholder="Mesajınızı yazınız."
                        as="textarea"
                        style={{
                          color: "#dfdee2",
                          padding: "10px",
                          boxSizing: "border-box",
                          width: "100%",
                          height: "200px",
                          borderColor: "rgba(0,0,0,0.8)",
                        }}
                      />
                    </Form.Group>

                    <p>{errorMessage}</p>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        style={{ marginBottom: "15px", width: "50%" }}
                        className="btn btn-primary checkOutButton w-100"
                        type="submit"
                      >
                        KAYDET
                      </Button>
                    </div>
                  </Form>
                </Tab.Pane>

                <Tab.Pane eventKey="sik-sorulan-sorular">
                  <TabTitle>
                    <div>SIK SORULAN SORULAR</div>
                  </TabTitle>
                  {sss &&
                    sss.map((e, index) => {
                      return (
                        <div key={index}>
                          <Line />
                          <div style={{ marginTop: "30px" }}>
                            <div style={{ fontSize: "18px" }}>{e.Q}</div>
                            <div style={{ marginTop: "10px" }}>{e.A}</div>
                          </div>
                        </div>
                      );
                    })}
                  <Line></Line>
                </Tab.Pane>
              </TabContent>
            </Col>
          </Row>
        </Tab.Container>
      </div>
      <HooxModal
        isShowing={isShowingSuccess}
        hide={toggleSuccessModal}
        title="BAŞARILI"
      >
        <p style={{ color: "#7b748c" }}>Bilgileriniz Güncellendi</p>
      </HooxModal>
      <HooxModal
        isShowing={contactModalVisible}
        hide={toggleContactModal}
        title="Bize Ulaşın"
        type={contactModalType ? HOOX_MODAL.SUCCESS : HOOX_MODAL.ERROR}
      >
        {" "}
        {contactModalType ? (
          <p style={{ color: "#7b748c" }}>
            Mesajınız bize ulaştı. En kısa sürede dönüş yapacağız.
          </p>
        ) : (
          <p style={{ color: "#7b748c" }}>
            Mesajınız gönderilemedi. Lütfen daha sonra tekrar deneyiniz.
          </p>
        )}
      </HooxModal>
      <HooxModal
        isShowing={isShowingDelete}
        hide={toggleDeleteModal}
        title="Hesabımı Sil"
        type={HOOX_MODAL.INFO}
      >
        {" "}
        {
          <div>
            <p style={{ color: "#7b748c" }}>
              Hesabınızı silmek istediğinizden emin misiniz?
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={styles.deleteButton}
                className="btn btn-primary cancelButton "
                type="button"
                onClick={toggleDeleteModal}
              >
                İPTAL
              </Button>
              <Button
                style={styles.deleteButton}
                className="btn btn-primary checkOutButton "
                type="button"
                onClick={handleDeleteAccount}
              >
                SİL
              </Button>
            </div>
          </div>
        }
      </HooxModal>
      <HooxModal
        isShowing={isShowingFinish}
        hide={toggleFinishModal}
        title="Aboneliğimi Sonlandır"
        type={subscriptionModalType ? HOOX_MODAL.SUCCESS : HOOX_MODAL.INFO}
      >
        {" "}
        {
          <div>
            <p style={{ color: "#7b748c" }}>{subscriptionModalText}</p>
            {subscriptionModalType ? null : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  style={styles.deleteButton}
                  className="btn btn-primary cancelButton "
                  type="button"
                  onClick={toggleFinishModal}
                >
                  İPTAL
                </Button>
                <Button
                  style={styles.deleteButton}
                  className="btn btn-primary checkOutButton "
                  type="button"
                  onClick={handleFinishSubscription}
                >
                  SONLANDIR
                </Button>
              </div>
            )}
          </div>
        }
      </HooxModal>
      <HooxModal
        isShowing={isShowingAvatar}
        hide={toggleAvatarModal}
        title="Avatar Seç"
        onlyTitle
        type={HOOX_MODAL.INFO}
      >
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
          {profiles.map((profile) => {
            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                    backgroundImage: `url(${profile.url})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    margin: "10px",
                  }}
                  onClick={() => {
                    onAvatarSelect(profile.url);
                    toggleAvatarModal();
                  }}
                ></div>
              </div>
            );
          })}
        </div>
      </HooxModal>
    </div>
  );
}
export default UserDetails;

const styles = {
  deleteButton: {
    marginBottom: "15px",
    width: "35%",
    marginRight: "5px",
    marginLeft: "5px",
  },
  editTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};
