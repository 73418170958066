import { Button } from "react-bootstrap";
import styled from "styled-components";

export const HooxButton = {
  Primary: styled(Button)`
    background-color: #ff0059;
    border-color: #ff0059;
    border-width: 2px;
    outline: 0;
    &:hover {
      border-color: #ff0059;
      background-color: #ff0059;
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    }
  `,
  Secondary: styled(Button)`
    border-color: #fff;
    border-width: 2px;
    color: ${(props) => (props.isfavorited === true ? "#FF0059" : "#FFF")};
    &:hover {
      background: none;
    }
  `,
};

export const ContentWrapper = styled.div`
  min-height: auto;
`;
