import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import "./welcome/styles.css";
import DiscoverButton from "../components/DiscoverButton";
import { Carousel, Row, Col, Button, Container } from "react-bootstrap";
import {
  ProductPageTitle,
  ProductBox,
  ProductTitle,
  ProductDescription,
  ProductPrice,
} from "./ProductSelection/ProductSelection.styles";
import { getProducts } from "../service/contentService";
import { useNavigate } from "react-router-dom";
function Welcome() {
  const navigate = useNavigate();
  const [products, setProducts] = useState(null);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 630px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const windowWidth = window.innerWidth;

  useEffect(() => {
    getProducts().then((response) => {
      setProducts(response.data);
      // TODO: select activeIndex logic
    });
  }, []);

  useEffect(() => {
    document.body.style.marginTop = 0;

    return () => {
      document.body.style.marginTop = null;
    };
  }, []);

  return (
    <>
      {isDesktopOrLaptop && (
        <>
          <div
            className="welcome"
          // style={{ backgroundImage: `url(/assets/img/background.png)` }}
          >
            <div style={{ marginBottom: "260px" }}>
              <img
                style={{ width: "100%" }}
                src={
                  "https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/52cb6edc-da62-47db-60f6-db3684626400/public"
                  //"https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/29af5fec-f3d1-41af-5cf7-5b19cdaea100/public"
                  //"https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/c33cfb28-d14d-4cb1-a9d9-85546caa3a00/public"
                }
                alt=""
              />
            </div>
            <div className="filler">
              <div className="text-container">
                <h1>İzlenecek</h1>
                <h1>çok şey var!</h1>
                <span>
                  Kapuçin Maymunlarının her şeyin aslında para olmadığını nasıl
                  öğrendiklerinden, zamanında içinde sirk bile olan o meșhur
                  Beyoğlu pasajınin hikayesine kadar izlenecek çok şey var!
                </span>
              </div>
            </div>
          </div>
          <div className="carousel">
            <Carousel controls={false}>
              <Carousel.Item>
                <img
                  className="bd-placeholder-img carousel-image"
                  src={
                    "https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/06e5aeb7-9fa7-4e62-c625-1b1082aeed00/public"
                  }
                  alt=""
                />
                <DiscoverButton
                  onButtonClick={() => navigate("/user/register")}
                  style={{
                    position: "absolute",
                    left: windowWidth / 8,
                    top: windowWidth / 5,
                  }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="bd-placeholder-img carousel-image"
                  src={
                    "https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/274f4c76-86e8-4a7b-9135-1e26a1edba00/public"
                  }
                  alt=""
                />
                <DiscoverButton
                  onButtonClick={() => navigate("/user/register")}
                  style={{
                    position: "absolute",
                    left: windowWidth / 8,
                    top: windowWidth / 5,
                  }}
                />
              </Carousel.Item>
            </Carousel>
          </div>

          <Row>
            <div className="text-center">
              <img
                style={{
                  width: "50%",
                  marginTop: "250px",
                  marginBottom: "50px",
                }}
                src={
                  "https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/207b52f3-f93a-4660-3260-bd78e4b68c00/public"
                }
                alt=""
              />
            </div>
          </Row>

          <div className="text-container">
            <h1>Mars için kesin bir söz söyleyemeyiz.</h1>
            <span>
              Bir tablet, bir telefon, bir televizyon ve bir de bilgisayar hep
              birlikte film izlemek için yola çıkmışlar... Tabii ki internet
              olan her yerden istediğin zaman izlersin. Ama Mars'a fiber alt
              yapı gitti mi henüz bilmiyoruz.
            </span>
          </div>
          <Row>
            <div className="text-center">
              <img
                style={{
                  width: "100%",
                  marginTop: "250px",
                  marginBottom: "50px",
                }}
                src={
                  "https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/a16eb849-415b-4d5b-61b6-1c6ee8b29100/public"
                  //"https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/13cbe8a8-d2fc-4e0a-ee24-aa0743723a00/public"
                }
                alt=""
              />
            </div>
          </Row>

          <div className="text-container">
            <h1>Çay molaları şimdi daha bir anlamlı!</h1>
            <span>
              Çünkü Hoox'ta çay molalarında bile bir bölümünü bitirebileceğin
              kısa yapımlar var.
            </span>
          </div>
          <div className="product-selection">
            <Row style={{ marginTop: "200px" }}>
              <Col>
                <ProductPageTitle className="text-center m-3">
                  SİZE UYGUN PAKETİ SEÇİN
                </ProductPageTitle>
              </Col>
            </Row>
            <Row>
              <Col>
                <Container>
                  <Row className="justify-content-center">
                    {products &&
                      products.slice(0, 2).map((product, index) => (
                        <Col md={3} key={index}>
                          <ProductBox key={index} className="text-center p-4">
                            <div className="mb-5">
                              <i
                                className={`bi ${product.imageUrl ? product.imageUrl : "bi-star"
                                  }`}
                                style={{ color: "#12d6df", fontSize: "60px" }}
                              ></i>
                            </div>
                            <ProductTitle>{product.name}</ProductTitle>
                            <hr
                              style={{
                                color: "#12d6df",
                                opacity: "1",
                                margin: "10px 0 15px",
                              }}
                            />
                            <ProductDescription>
                              {product.description}
                            </ProductDescription>
                            <ProductPrice>{product.priceWithTax} TL</ProductPrice>
                          </ProductBox>
                        </Col>
                      ))}
                  </Row>
                </Container>
              </Col>
            </Row>
            <Row>
              <Col className="text-center mt-3">
                <Button
                  style={{ color: "#fff", width: "20vh" }}
                  className="btn btn-primary checkOutButton"
                  type="button"
                  onClick={() => navigate("user/register")}
                >
                  ÜYE OL
                </Button>
              </Col>
            </Row>
          </div>
        </>
      )
      }
      {
        isTabletOrMobile && (
          <>
            <div
              className="welcome"
            // style={{ backgroundImage: `url(/assets/img/background.png)` }}
            >
              <div className="crop">
                <img
                  style={{ width: "100%" }}
                  src={
                    "https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/c33cfb28-d14d-4cb1-a9d9-85546caa3a00/public"
                  }
                  alt=""
                />
              </div>
              <div className="filler-responsive">
                <div className="text-container">
                  <h1>İzlenecek</h1>
                  <h1>çok şey var!</h1>
                  <span>
                    Kapuçin Maymunları'nın her şeyin aslında para olmadığını nasıl
                    öğrendiklerinden, zamanında içinde sirk bile olan o meșhur
                    Beyoğlu pasajının hikayesine kadar izlenecek çok şey var!
                  </span>
                </div>
              </div>
            </div>
            <Row className="mb-5 mr-0">
              <Col className="text-center mt-3">
                <Button
                  style={{ color: "#fff", width: "22vh" }}
                  className="btn btn-primary checkOutButton mt-3 mb-5"
                  type="button"
                  onClick={() => navigate("user/register")}
                >
                  HEMEN ÜYE OL
                </Button>
              </Col>
            </Row>
            <div className="carousel crop-carousel">
              <Carousel controls={false}>
                <Carousel.Item>
                  <img
                    className="bd-placeholder-img carousel-image"
                    src={
                      "https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/06e5aeb7-9fa7-4e62-c625-1b1082aeed00/public"
                    }
                    alt=""
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="bd-placeholder-img carousel-image"
                    src={
                      "https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/274f4c76-86e8-4a7b-9135-1e26a1edba00/public"
                    }
                    alt=""
                  />
                </Carousel.Item>
              </Carousel>
            </div>
            <Row>
              <DiscoverButton
                onButtonClick={() => navigate("/user/register")}
                style={{}}
                isMobile
              />
            </Row>
            <Row>
              <div className="text-center">
                <img
                  style={{
                    width: "100%",
                    marginTop: "80px",
                  }}
                  src={
                    "https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/207b52f3-f93a-4660-3260-bd78e4b68c00/public"
                  }
                  alt=""
                />
              </div>
            </Row>
            <div className="filler-responsive">
              <div className="text-container">
                <h1>Mars için kesin bir söz söyleyemeyiz.</h1>
                <span>
                  Bir tablet, bir telefon, bir televizyon ve bir de bilgisayar hep
                  birlikte film izlemek için yola çıkmışlar... Tabii ki internet
                  olan her yerden istediğin zaman izlersin. Ama Mars'a fiber
                  altyapı gitti mi henüz bilmiyoruz.
                </span>
              </div>
            </div>
            <Row>
              <div className="text-center">
                <img
                  style={{
                    width: "100%",
                    marginTop: "120px",
                  }}
                  src={
                    "https://imagedelivery.net/qoBmvbtK9_6g_NBy8qZRPw/4aa6673f-5321-42c0-625a-b21c7f6c1400/public"
                  }
                  alt=""
                />
              </div>
            </Row>
            <div className="filler-responsive">
              <div className="text-container">
                <h1>Çay molaları şimdi daha bir anlamlı!</h1>
                <span>
                  Çünkü Hoox'ta çay molalarında bile bir bölümünü bitirebileceğin
                  kısa yapımlar var.
                </span>
              </div>
            </div>
            <div className="product-selection">
              <Row style={{ marginTop: "144px" }}>
                <Col>
                  <ProductPageTitle className="text-center m-3">
                    SİZE UYGUN PAKETİ SEÇİN
                  </ProductPageTitle>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Container>
                    <Row className="justify-content-center">
                      {products &&
                        products.slice(0, 2).map((product, index) => (
                          <Col md={4}>
                            <ProductBox key={index} className="text-center p-4 mb-5">
                              <div className="mb-5">
                                <i
                                  className={`bi ${product.imageUrl ? product.imageUrl : "bi-star"
                                    }`}
                                  style={{ color: "#12d6df", fontSize: "60px" }}
                                ></i>
                              </div>
                              <ProductTitle>{product.name}</ProductTitle>
                              <hr
                                style={{
                                  color: "#12d6df",
                                  opacity: "1",
                                  margin: "10px 0 15px",
                                }}
                              />
                              <ProductDescription>
                                {product.description}
                              </ProductDescription>
                              <ProductPrice>{product.priceWithTax} TL</ProductPrice>
                            </ProductBox>
                          </Col>
                        ))}
                    </Row>
                  </Container>
                </Col>
              </Row>
              <Row>
                <Col className="text-center mt-3 d-flex justify-content-center">
                  <button
                    style={{
                      borderRadius: "6px",
                      border: "1px solid #bc00bc",
                      width: "180px",
                      height: "40px",
                      backgroundColor: "transparent",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                    className="btn btn-secondary outline-primary"
                    type="button"
                    onClick={() => navigate("user/register")}
                  >
                    ÜYE OL
                  </button>
                </Col>
              </Row>
            </div>
          </>
        )
      }
    </>
  );
}

export default Welcome;
