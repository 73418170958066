import React, { useState, useEffect } from "react";
import { addBookmark } from "../../service/contentService";
import "./style.css";
/**
 * Component consuming the VdocipherAPI
 */
const VideoLayer = ({
  videoRef,
  isAPIReady,
  isVisible,
  setVisible,
  style,
  content,
  watchingContent,
}) => {
  const [status, setStatus] = useState("NA");
  const [player, setPlayer] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    if (!isAPIReady) return;
    if (!videoRef) {
      // @todo detach from the API
      setPlayer(null);
      return;
    }
    const player = new window.VdoPlayer(videoRef);
    window.player = player;
    setPlayer(player);
    // player.video.play();
    player.video.addEventListener("play", () => setStatus("Playing"));
    player.video.addEventListener("pause", () => setStatus("Paused"));
    player.video.addEventListener("canplay", () => {
      setStatus("Ready");
      player.video.play();
    });
    player.video.addEventListener("timeupdate", () => {
      setCurrentTime(player.video.currentTime);
    });
    window.player = player;
  }, [isAPIReady, videoRef]);

  useEffect(() => {
    if (!player || !content) return;
    getContentBookmark();
    player.video.play();
    return () => {
      handleBookmark();
    };
  }, [player, content]);

  const onPress = async () => {
    if (player.video.paused) {
      player.video.play();
    } else {
      player.video.pause();
      await handleBookmark();
      setVisible(false);
    }
  };

  const handleForwards = () => {
    player.video.currentTime = player.video.currentTime + 10;
  };

  const handleBackwards = () => {
    player.video.currentTime = player.video.currentTime - 10;
  };

  const getContentBookmark = async () => {
    if (!watchingContent || !watchingContent.bookmarkTime)
      player.video.currentTime = 0;
    player.video.currentTime = watchingContent.bookmarkTime;
  };

  const handleBookmark = async () => {
    await addBookmark(
      watchingContent.id,
      player.video.currentTime,
      watchingContent.duration
    );
  };

  // console.log({ player, content, watchingContent });
  return player && player.video && isVisible ? (
    <div style={style} onClick={onPress}>
      <h1 style={styles.title}>{content.title} </h1>
      {content.seasons && content.seasons.length ? (
        <h5 style={styles.info}>
          {watchingContent.type === "EPISODE" ? watchingContent.title : " "}
        </h5>
      ) : null}
      {/* <div>Controls via API</div>
      <div className="btn" onClick={handleBackwards}>
        -10
      </div>
      <div>Status: {status}</div>
      <div>CurrentTime: {currentTime}</div>
      <div className="btn" onClick={handleForwards}>
        +10
      </div> */}
    </div>
  ) : null;
};

export default VideoLayer;

const styles = {
  title: {
    color: "white",
    marginLeft: "74px",
    marginTop: "44px",
  },
  info: {
    color: "white",
    marginLeft: "74px",
    marginTop: "8px",
  },
};
