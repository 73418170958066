import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import useModal from "../../components/hooks/useModal";
import HooxModal, { HOOX_MODAL } from "../../components/HooxModal";
import PaymentModal from "../../components/PaymentModal";
import { getProducts } from "../../service/contentService";
import {
  PaymentInfo,
  ProductBox,
  ProductDescription,
  ProductPageTitle,
  ProductPrice,
  ProductTitle,
} from "./ProductSelection.styles";

const ProductSelection = () => {
  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState(null);

  const [activeIndex, setActiveIndex] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [paymentToken, setPaymentToken] = useState(null);

  const { isShowing: isShowingSuccess, toggle: toggleSuccessModal } =
    useModal();

  let { guid: customerGuid } = useParams();

  useEffect(() => {
    getProducts().then((response) => {
      setProducts(response.data);
      // TODO: select activeIndex logic
    });
  }, []);

  const handleProductSelection = (product, index) => () => {
    setProduct(product);
    setActiveIndex(index);
  };

  const navigation = useNavigate();

  const handleSubmit = async () => {
    navigation("/payment-direct/" + customerGuid + "/" + product.externalId);
  };

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  useEffect(() => {
    window.iFrameResize({}, "#paytriframe");
  }, [modalIsOpen, paymentToken]);

  /*
  const handleSwiperChange = function () {
    setActiveIndex(this.activeIndex);

    products
      .filter((item, index) => index === this.activeIndex)
      .map((prd) => setProduct(prd));
  };
*/
  return (
    <Container>
      <Row>
        <Col>
          <ProductPageTitle className="text-center m-3">
            SİZE UYGUN PAKETİ SEÇİN
          </ProductPageTitle>
        </Col>
      </Row>
      <Row>
        <Col>
          <Container>
            <Row className="justify-content-center m-3">
              {products &&
                products.slice(0, 2).map((product, index) => (
                  <Col md={3} onClick={handleProductSelection(product, index)}>
                    <ProductBox
                      key={index}
                      className="text-center p-4 mb-5"
                      isActive={activeIndex === index}
                    >
                      <div className="mb-5">
                        <i
                          className={`bi ${
                            product.imageUrl ? product.imageUrl : "bi-star"
                          }`}
                          style={{ color: "#12d6df", fontSize: "60px" }}
                        ></i>
                      </div>
                      <ProductTitle>{product.name}</ProductTitle>
                      <hr
                        style={{
                          color: "#12d6df",
                          opacity: "1",
                          margin: "10px 0 15px",
                        }}
                      />
                      <ProductDescription>
                        {product.description}
                      </ProductDescription>
                      <ProductPrice>{product.priceWithTax} TL</ProductPrice>
                    </ProductBox>
                  </Col>
                ))}
            </Row>
          </Container>
        </Col>
      </Row>
      <Row>
        <Col className="text-center mt-3">
          <PaymentInfo>
            **Aylık abonelik yaptırdığınızda iptal etmediğiniz sürece otomatik
            yenilenir.
          </PaymentInfo>
        </Col>
      </Row>
      <Row>
        <Col className="text-center mt-3">
          <Button
            style={{ color: "#fff" }}
            className="btn btn-primary checkOutButton"
            type="submit"
            onClick={handleSubmit}
          >
            ÖDEME YAP
          </Button>

          <PaymentModal
            isShowing={modalIsOpen}
            hide={() => setModalIsOpen(false)}
          >
            <iframe
              title="odeme-entegrasyonu"
              src={`https://www.paytr.com/odeme/guvenli/${paymentToken}`}
              id="paytriframe"
              frameBorder="0"
              scrolling="no"
              style={{ width: "100%" }}
            ></iframe>
          </PaymentModal>

          <HooxModal
            isShowing={isShowingSuccess}
            hide={toggleSuccessModal}
            title="İşlem Başarısız."
            type={HOOX_MODAL.INFO}
          >
            <p style={{ color: "#ffffff" }}>
              Aktif Aboneliğiniz Bulunmaktadır.
            </p>
          </HooxModal>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductSelection;
