import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

const BackModal = styled(Modal)`
  width: 100vw;
  height: 100vh;
  // background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  bottom: 0;
  top: auto;
  & .modal-dialog {
    // display: none;
  }
`;

function PaymentModal({ isShowing, hide, children }) {
  return (
    <BackModal
      show={isShowing}
      onHide={hide}
      backdrop={false}
      // dialogClassName="modal-90w"
    >
      {children}
    </BackModal>
  );
}

export default PaymentModal;
