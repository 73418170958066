import React, { useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import useModal from "../components/hooks/useModal.js";
import HooxModal, { HOOX_MODAL } from "../components/HooxModal";
import { useMediaQuery } from "react-responsive";
import { apiUrl } from "../util/apiConfig.js";

const FormControl = styled(Form.Control)`
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-radius: 7px !important;
  resize: none;
  height: 40px;
  font: normal normal normal 14px Montserrat;
  &:focus-visible {
    outline: rgba(0, 0, 0, 0.8) !important;
  }
`;

const ContentDiv = styled.div`
  color: #fff;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  padding: 30px;
  font-size: 13px;
  width: 50%;
`;

const Title = styled.div`
  font-size: 23px;
  font-weight: 500;
`;

const Line = styled("hr")`
  color: #12d6df;
  opacity: 1;
`;

const CCDateField = styled(Form.Control)`
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-radius: 7px !important;
  resize: none;
  height: 40px;
  margin-right: 10px;
  font: normal normal normal 14px Montserrat;
  &:focus-visible {
    outline: rgba(0, 0, 0, 0.8) !important;
  }
`;

const CCSelect = styled.select`
  width: 30%;
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-radius: 7px !important;
  resize: none;
  height: 40px;
  margin-right: 10px;
  font: normal normal normal 14px Montserrat;
  color: white;
  &:focus-visible {
    outline: rgba(0, 0, 0, 0.8) !important;
  }
  border: rgb(89, 81, 116) !important;
`;

export default function PaymentDirect() {
  // get params
  const { guid, packageId } = useParams();
  const { isShowing: modalVisible, toggle: toggleModal } = useModal();
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });

  const handleSubmit = (event) => {
    const { holderName, cardNumber, expiryMonth, expiryYear, cvv } =
      Object.values(event.currentTarget.elements)
        .slice(0, 7)
        .reduce((acc, cur) => {
          acc[cur.name] = cur.value;
          return acc;
        }, {});
    // name regex
    const nameRegex = /^[a-zA-Z0-9ğüşöçıİĞÜŞÖÇ ]{2,30}$/;
    // card number regex
    const cardNumberRegex = /^[0-9]{16}$/;
    // expiry month regex
    const expiryMonthRegex = /^[0-9]{2}$/;
    // expiry year regex
    const expiryYearRegex = /^[0-9]{2}$/;
    // cvv regex
    const cvvRegex = /^[0-9]{3}$/;
    if (
      !nameRegex.test(holderName) ||
      !cardNumberRegex.test(cardNumber) ||
      !expiryMonthRegex.test(expiryMonth) ||
      !expiryYearRegex.test(expiryYear) ||
      !cvvRegex.test(cvv)
    ) {
      event.preventDefault();
      toggleModal();
      return;
    }
  };

  const digitInput = (event, length) => {
    const { value } = event.target;

    if (value.length > length) {
      event.target.value = value.slice(0, length);
    } else if (!/^\d$/.test(value[value.length - 1])) {
      event.target.value = value.slice(0, value.length - 1);
    }
    value = value.match(/.{1,4}/g);
    value = value.join(" ");
    event.target.value = value;
  };

  const textInput = (event) => {
    const { value } = event.target;

    if (/^\d$/.test(value[value.length - 1])) {
      event.target.value = value.slice(0, value.length - 1);
    }
  };

  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  function handleMonthChange(event) {
    setMonth(event.target.value);
  }

  function handleYearChange(event) {
    setYear(event.target.value);
  }

  const months = [
    { value: "", label: "Ay" },
    { value: "01", label: "01" },
    { value: "02", label: "02" },
    { value: "03", label: "03" },
    { value: "04", label: "04" },
    { value: "05", label: "05" },
    { value: "06", label: "06" },
    { value: "07", label: "07" },
    { value: "08", label: "08" },
    { value: "09", label: "09" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
  ];

  const years = [
    { value: "", label: "Yıl" },
    { value: "23", label: "23" },
    { value: "24", label: "24" },
    { value: "25", label: "25" },
    { value: "26", label: "26" },
    { value: "27", label: "27" },
    { value: "28", label: "28" },
    { value: "29", label: "29" },
    { value: "30", label: "30" },
    { value: "31", label: "31" },
    { value: "32", label: "32" },
    { value: "33", label: "33" },
  ];

  return (
    <div
      className="container"
      style={{ marginTop: "10vh", marginBottom: "10vh" }}
    >
      <Row className={isMobile ? "justify-content-center" : ""}>
        <ContentDiv style={isMobile ? { width: "75%" } : {}}>
          <Title>Kredi Kartı Ödeme</Title>
          <Line />
          <Form
            action={apiUrl + "/cus/subscriber/start/payment/dapi"}
            method="post"
            encType="multipart/form-data"
            style={{ marginTop: "30px" }}
            onSubmit={handleSubmit}
          >
            <Form.Group className="mb-3">
              <Form.Label htmlFor="name">Kart Üzerindeki İsim</Form.Label>
              <FormControl
                id="name"
                name="holderName"
                placeholder="İsim Soyisim"
                onChange={textInput}
                required
              />
            </Form.Group>

            <input type="hidden" name="customerGuid" value={guid} />
            <input type="hidden" name="packageId" value={packageId} />

            <Form.Group className="mb-3">
              <Form.Label htmlFor="kkno">Kredi kartı Numarası</Form.Label>
              <FormControl
                id="kkno"
                name="cardNumber"
                placeholder="0000 0000 0000 0000"
                onChange={(event) => digitInput(event, 16)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label htmlFor="expiryMonth">Son Kullanma Tarihi</Form.Label>
              <div style={{ display: "flex" }}>
                <CCSelect
                  value={month}
                  onChange={handleMonthChange}
                  id="expiryMonth"
                  name="expiryMonth"
                  required
                >
                  {months.map((month) => (
                    <option key={month.value} value={month.value}>
                      {month.label}
                    </option>
                  ))}
                </CCSelect>

                <CCSelect
                  value={year}
                  onChange={handleYearChange}
                  id="expiryYear"
                  name="expiryYear"
                  required
                >
                  {years.map((year) => (
                    <option key={year.value} value={year.value}>
                      {year.label}
                    </option>
                  ))}
                </CCSelect>

                <CCDateField
                  id="cvv"
                  name="cvv"
                  placeholder="CVV"
                  onChange={(event) => digitInput(event, 3)}
                  required
                />
              </div>
            </Form.Group>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                className="btn btn-primary checkOutButton w-100"
                type="submit"
                style={{ marginTop: "15px", width: "50%" }}
              >
                ÖDEME YAP
              </Button>
            </div>
          </Form>
        </ContentDiv>
      </Row>
      <HooxModal
        isShowing={modalVisible}
        hide={toggleModal}
        title={"Lütfen bütün alanları kontrol ediniz."}
        type={HOOX_MODAL.ERROR}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button variant="secondary" onClick={toggleModal}>
            Kapat
          </Button>
        </div>
      </HooxModal>
    </div>
  );
}
