export const USER_RIGHT_NOT_IN_PACKAGE = 0;
export const USER_RIGHT_IN_PACKAGE = 1;
export const USER_RIGHT_BUY_PACKAGE = 2;

export const isMobile = "420px";
export const isMiddle = "800px";

export const sizes = {
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  "2xl": "1536px",
};

export const POSTER_WEB_BANNER = 3;
export const POSTER_WEB_DETAIL = 9;
export const POSTER_WEB_LIST = 8;
