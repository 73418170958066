import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";

export const ProductWrapper = styled("div")`
  // display: flex;
  // flex-direction: column;
  // justify-content: space-around;
  // align-items: center;
  // height: 80vh;
  // background-size: cover;
`;

export const ProductPageTitle = styled.h1`
  color: #fff;
  font-size: 30px;
  letter-spacing: 4px;
  height: 3%;
`;

export const ProductList = styled.div`
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // width: 100%;
  // height: 100%;
`;

export const ProductBox = styled.div`
  // display: flex;
  // justify-content: center;
  // align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  // width: 17%;
  // height: 80%;
  // min-width: 270px;
  border-radius: 5px;
  border: 2px solid
    ${(props) => (props.isActive ? "#BC00BC" : "rgba(18 214 223 / 30%)")};
  transform: scale(${(props) => (props.isActive ? "1.1" : "1")});
  min-height: 390px;
`;

export const ProductDetail = styled("div")`
  // width: 100%;
  // margin-right: 10%;
  // margin-left: 10%;
  // color: #fff;
  // height: 70%;
`;

export const ProductTitle = styled.h2`
  color: #bc00bc;
  font-size: 20px;
`;

export const ProductDescription = styled.div`
  color: #fff;
  font-family: "GilroyLight";
  // font-size: 16px;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-evenly;
  // line-height: 1.5;
`;

export const ProductSwiper = styled(Swiper)`
  // height: 100%;
  // width: 50%;
  // min-width: 920px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  padding: 30px;
`;

export const ProductSwiperSlide = styled(SwiperSlide)`
  // height: 100%;
  display: flex;
  // justify-content: center;
  // align-items: center;
`;

export const ProductPrice = styled.div`
  color: #fff;
  font-size: 24px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // margin-top: 15px;
  // font-family: GilroyLight;
  // font-size: 20px;
`;

export const PaymentInfo = styled.div`
  color: #bcb6b6;
  font-family: "GilroyLight";
  font-size: 12px;
`;
